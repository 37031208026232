import React, { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useToast } from "@hooks/useToast";
import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";
import { H4, H5 } from "@components/Heading";
import Span from "@components/Span";
import {
  PrimaryButton,
  PrimaryButtonIconOutlined,
  PrimaryButtonOutlined,
} from "@components/Button";
import Icon from "@components/Icon";
import Editor from "@components/Editor";
import ProgressSpinner from "@components/ProgressSpinner";

import { formatDateAndTime } from "@utils/utils";
import editComment from "@app/services/assignments/editComment";
import deleteComment from "@app/services/assignments/deleteComment";
import getCaseDetailsById from "@app/services/cases/getCaseDetailsById";
import updateCaseDetailsById from "@app/services/cases/updateCaseDetailsById";
import Card from "@components/Card";

const StyledCommentBody = styled.div`
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
  ul {
    margin-top: 10px;
    li {
      margin-left: 20px;
      margin-top: 5px;

      &::before {
        content: "- ";
      }
    }
  }
`;

const CommentsTab = () => {
  const { messages } = useIntl();
  const { case_id } = useParams();
  const { showSuccessToast, showErrorToast } = useToast();
  const userInfo = useSelector((state) => state.authReducer.userInfo);

  const [newComment, setNewComment] = useState("");
  const [isEditComment, setIsEditComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const editedCommentId = useRef(null);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await getCaseDetailsById(case_id);
      const { data: { comments: resComments = [] } = {} } = response;
      setComments(resComments);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
    }
  }, [case_id]);

  useEffect(() => {
    fetchData();
  }, [case_id, fetchData]);

  const handleOnChangeComment = (event) => {
    const { htmlValue } = event;
    setNewComment(htmlValue);
  };

  const handleAddComment = async () => {
    const newComments = comments;
    const currentDate = new Date();

    newComments.push({
      comment: newComment,
      created_at: currentDate.toISOString(),
      user: {
        name: userInfo?.name,
        type: userInfo?.type,
        id: userInfo?.id,
      },
    });
    setComments(newComments);
    const payload = {
      comment: newComment,
      user_id: userInfo?.id,
    };
    try {
      const { data = {} } = await updateCaseDetailsById(case_id, payload);
      setComments(data?.comments || comments);

      showSuccessToast(messages.label_comment_added);
    } catch (error) {
      showErrorToast(messages.error_try_later);
    }
    setNewComment("");
  };

  const handleEditComment = (commentKey, commentId) => {
    setIsEditComment(true);
    setNewComment(comments[commentKey].comment);

    editedCommentId.current = commentId;
  };

  const handleCancelEditComment = () => {
    setIsEditComment(false);
    setNewComment("");
  };

  const handleConfirmEditComment = async () => {
    handleCancelEditComment();

    if (editedCommentId.current == null) {
      return;
    }

    const newComments = comments?.map((commentObject) =>
      commentObject.id === editedCommentId.current
        ? {
            ...commentObject,
            comment: newComment,
          }
        : commentObject,
    );

    const editedComment = newComments?.find(
      (commentObject) => commentObject.id === editedCommentId.current,
    );
    setComments(newComments);

    try {
      await editComment(editedComment);
      showSuccessToast(messages.label_comment_updated);
    } catch (error) {
      showErrorToast(messages.error_try_later);
    }
  };

  const handleDeleteComment = async (commentKey, commentId) => {
    let newComments = comments;
    delete newComments[commentKey];
    setComments(newComments);

    try {
      await deleteComment(commentId);
      showSuccessToast(messages.label_comment_deleted);
      fetchData();
    } catch (error) {
      showErrorToast(messages.error_try_later);
    }
  };

  return (
    <Div>
      {isFetching && <ProgressSpinner />}

      {!isFetching && (
        <Card p={3} width={[1, 1, 8 / 10]}>
          <Div
            pb={10}
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid var(--grey-lightest)"
          >
            <H4>{messages.label_comments}</H4>
            <H5>{messages.label_internal_storge}</H5>
          </Div>
          {comments?.map((comment, key) => {
            const { comment: text = "", created_at = "", user = {} } = comment;

            return (
              <Div width={1} key={created_at} my={3}>
                <Div
                  display={["block", "flex"]}
                  justifyContent="space-between"
                  mb={2}
                >
                  <Div display="flex" alignItems="center">
                    <Icon
                      name="account-circle"
                      mr={1}
                      color="var(--turquoise)"
                      fontSize="var(--fs-h3)"
                    />
                    <H4 fontWeight="var(--semibold-weight) !important" mr={4}>
                      {user?.name}
                    </H4>
                    <Span
                      color="var(--light-gray) !important"
                      fontWeight="var(--medium-weight) !important"
                      lineHeight="21px"
                    >
                      {user?.type}
                    </Span>
                  </Div>
                  <Div display="flex" alignItems="center" gridGap={2}>
                    <Span color="var(--grey) !important" light>
                      {formatDateAndTime(created_at)}
                    </Span>
                    <Icon
                      name="pen"
                      fontSize="var(--fs-icon)"
                      color="var(--turquoise)"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleEditComment(key, comment.id);
                      }}
                    />
                    <Icon
                      name="trash"
                      fontSize="var(--fs-icon-m)"
                      color="var(--turquoise)"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleDeleteComment(key, comment.id);
                      }}
                    />
                  </Div>
                </Div>
                <Div>
                  <StyledCommentBody
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </Div>
              </Div>
            );
          })}
          <Div mt={comments?.length ? 4 : 3}>
            <TextMediumWeight>{messages.label_add_comment}</TextMediumWeight>
            <Editor
              width={1}
              height={"150px"}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "bullet" }],
                ],
              }}
              my={2}
              value={newComment}
              onTextChange={handleOnChangeComment}
            />
          </Div>
          <Div width={1} display="flex" justifyContent="flex-end">
            <Div mt={60}>
              {isEditComment ? (
                <>
                  <PrimaryButtonOutlined
                    rounded
                    mr={30}
                    label={messages.label_cancel}
                    onClick={handleCancelEditComment}
                  />
                  <PrimaryButton
                    rounded
                    label={messages.text_add}
                    onClick={handleConfirmEditComment}
                  />
                </>
              ) : (
                <PrimaryButtonIconOutlined
                  rounded
                  height={40}
                  px={22}
                  icon={
                    <Icon
                      name="add-comment"
                      mr={1}
                      fontSize={"var(--fs-icon-m)"}
                      fontWeight={"var(--medium-weight)"}
                    />
                  }
                  label={messages.label_add_comment}
                  onClick={handleAddComment}
                  alignItems={"end"}
                />
              )}
            </Div>
          </Div>
        </Card>
      )}
    </Div>
  );
};

export default CommentsTab;
