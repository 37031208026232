import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import * as Yup from "yup";

import Div from "@components/Div";

import fileUploadService from "@app/services/files/fileUploadService";
import { deleteCustomerProfile } from "@app/services/users/deleteUserProfileService";
import editCustomerInfoService from "@app/services/users/editCustomerInfoService";
import userService from "@app/services/users/userService";

import { PrimaryButtonIconOutlined } from "@components/Button";
import ConfirmationPopup from "@components/ConfirmationPopup";
import EditableFieldsCard from "@components/EditableFieldsCard";
import Icon from "@components/Icon";
import ProgressSpinner from "@components/ProgressSpinner";

import CompanyType from "@constants/companyTypeOptions";
import ConsentDropDownOptions from "@constants/consentDropDownOptions";
import CustomerStatus from "@constants/customerStatus";
import YesnoOptions from "@constants/yesnoOptions";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { SVLANG, ROUTES } from "@utils/constant";
import { FORM_INPUT_TYPE } from "@utils/enum";
import {
  addHyphenToNumber,
  ValidateOrganisationNumber,
  formatDate,
} from "@utils/utils";

const NameSchema = Yup.object().shape({
  account_manager_id: Yup.string().required(
    <FormattedMessage id="validation_empty_user_id" />
  ),
  company_name: Yup.string()
    .typeError(<FormattedMessage id="validation_empty_company_name" />)
    .required(<FormattedMessage id="validation_empty_company_name" />),
  address: Yup.string().required(
    <FormattedMessage id="validation_empty_company_address" />
  ),
  city: Yup.string().required(<FormattedMessage id="validation_enter_city" />),
  zip_code: Yup.string().required(
    <FormattedMessage id="validation_enter_zip_code" />
  ),
  registration_nr: Yup.string()
    .max(11, <FormattedMessage id="validation_valid_company_number" />)
    .required(<FormattedMessage id="validation_valid_company_number" />)
    .test({
      name: "valid-company-number",
      message: <FormattedMessage id="validation_valid_company_number" />,
      test: ValidateOrganisationNumber,
    }),
});

const ContactSchema = Yup.object().shape({
  firstname: Yup.string().required(
    <FormattedMessage id="validation_empty_name" />
  ),
  lastname: Yup.string().required(
    <FormattedMessage id="validation_empty_last_name" />
  ),
  email: Yup.string().required(
    <FormattedMessage id="validation_valid_email_input" />
  ),
  phone: Yup.string()
    .typeError(SVLANG.validation_valid_phone_number)
    .required(<FormattedMessage id="validation_empty_phone_number" />),
});

const SettingSchema = Yup.object().shape({
  is_active: Yup.string().required(
    <FormattedMessage id="validation_enter_status" />
  ),
  order_confirmation_email: Yup.string().required(
    <FormattedMessage id="validation_valid_email_notification" />
  ),
  integration_api_enabled: Yup.string().required(
    <FormattedMessage id="validation_empty_apiintegration" />
  ),
  company_type: Yup.string().required(
    <FormattedMessage id="validation_valid_company_type" />
  ),
});

const PubSchema = Yup.object().shape({
  pub_agreement: Yup.string().required(
    <FormattedMessage id="validation_empty_pub_agreement" />
  ),
  pub_date: Yup.string().when("pub_agreement", {
    is: pubAgreement => pubAgreement === 1,
    then: () =>
      Yup.string().required(
        <FormattedMessage id="validation_pub_agreement_required" />
      ),
    otherwise: () => Yup.string(),
  }),
});

const CompanyInfo = ({ customerData = {}, id = "0", updateCustomerData }) => {
  const { hasAllPermissions } = useAuthorization();
  const history = useHistory();
  const { messages } = useIntl();
  const { showSuccessToast, showErrorToast } = useToast();
  const userInfo = useSelector(state => state.authReducer.userInfo);

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const [user] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [setSelectedManagers] = useState(null);
  const [customerConsent] = useState(customerData.consent);

  const {
    company_name,
    address,
    registration_nr,
    phone,
    firstname,
    lastname,
    email,
    invoice,
    billing_email: billingEmail,
    city,
    zip_code,
    created_by,
    order_confirmation_email,
    test_account,
    pub_agreement,
    pub_date,
    integration_api_enabled,
    account_manager_id,
    is_active,
    company_type,
    document,
  } = customerData || {};

  // JSON VALUES FOR RENDERING ALL DROPDOWN AND MULTISELECT

  const consentOptions = ConsentDropDownOptions();
  const yesnoOptions = YesnoOptions();
  const customerStatus = CustomerStatus();
  const companyTypeOptions = CompanyType();

  const hasWriteAccessToCustomers = hasAllPermissions([
    AdminPermissions.AdminWriteCustomers,
  ]);

  const company = [
    {
      key: "company_name",
      translationKey: "label_company_name",
      value: companyDetails?.company_name,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "registration_nr",
      translationKey: "label_organisation_number",
      value: companyDetails?.registration_nr,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
      maxLength: 11,
    },
    {
      key: "address",
      translationKey: "label_company_address",
      value: companyDetails?.address,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "zip_code",
      translationKey: "label_postal_code",
      value: companyDetails?.zip_code,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "city",
      translationKey: "label_city",
      value: companyDetails?.city,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "account_manager_id",
      translationKey: "account_manager",
      value: companyDetails?.account_manager_id,
      type: FORM_INPUT_TYPE.DROPDOWN,
      values: { user },
      options: users,
      onChange: { setSelectedManagers },
      optionFields: users,
      optionLabel: "label",
      maxSelectedLabels: 3,
      className: "w-full md:w-20rem",
      label: { user },
      required: true,
    },
    {
      key: "created_by",
      translationKey: "label_created_by",
      value: userInfo?.name,
      type: FORM_INPUT_TYPE.TEXT,
      editable: false,
    },
    {
      key: "created_at",
      translationKey: "created_at",
      value: customerData.createdAt,
      type: FORM_INPUT_TYPE.CALENDAR,
      editable: false,
    },
  ];

  const contact = [
    {
      key: "firstname",
      translationKey: "label_firstname",
      value: companyDetails?.firstname,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "lastname",
      translationKey: "label_last_name",
      value: companyDetails?.lastname,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "email",
      translationKey: "label_email",
      value: companyDetails?.email,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "phone",
      translationKey: "label_phone",
      value: companyDetails?.phone,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
  ];

  const settings = [
    {
      key: "consent",
      translationKey: "consent_title",
      value: customerConsent,
      type: FORM_INPUT_TYPE.DROPDOWN,
      mt: 3,
      name: "consentValue",
      options: consentOptions,
      width: [1, 1, 450, 450],
      required: true,
    },
    {
      key: "is_active",
      translationKey: "consent_label_status",
      value: companyDetails?.is_active,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "is_active",
      options: customerStatus,
      required: true,
    },
    {
      key: "order_confirmation_email",
      translationKey: "order_email_notification",
      value: companyDetails?.order_confirmation_email,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "order_confirmation",
      options: yesnoOptions,
      required: true,
    },
    {
      key: "integration_api_enabled",
      translationKey: "api_integration",
      value: companyDetails?.integration_api_enabled,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "api_integration",
      options: yesnoOptions,
      required: true,
    },
    {
      key: "test_account",
      translationKey: "test_account",
      value: companyDetails?.test_account,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "testaccount",
      options: yesnoOptions,
      required: true,
    },
    {
      key: "company_type",
      translationKey: "label_company_type",
      value: companyDetails?.company_type,
      type: FORM_INPUT_TYPE.DROPDOWN,
      options: companyTypeOptions,
      required: true,
    },
  ];

  const pub = [
    {
      key: "pub_agreement",
      translationKey: "pub_agreement",
      value: companyDetails?.pub_agreement,
      type: FORM_INPUT_TYPE.DROPDOWN,
      name: "PUBAgreement",
      options: yesnoOptions,
      required: true,
    },
    {
      key: "pub_date",
      translationKey: "pub_date",
      value: formatDate(companyDetails?.pub_date),
      type: FORM_INPUT_TYPE.CALENDAR,
      show: companyDetails => companyDetails?.pub_agreement === 1,
    },
    {
      key: "document",
      translationKey: "customer_pub_document",
      type: FORM_INPUT_TYPE.FILE_UPLOAD,
      label: "Upload File",
      value: companyDetails?.document,
    },
  ];

  const handleDeleteProfile = () => {
    setIsDeleteDialogVisible(true);
  };

  const handleCancelDeleteProfile = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleDeleteSubmit = async () => {
    try {
      await deleteCustomerProfile({
        customerId: id,
      });

      showSuccessToast(messages.text_deleted_successful);

      history.push(ROUTES.ADMIN_CUSTOMERS.URL);
      return;
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  useEffect(() => {
    setCompanyDetails({
      company_name,
      address,
      registration_nr: addHyphenToNumber(registration_nr, "6"),
      phone: phone,
      firstname,
      lastname,
      email,
      invoice: invoice === 1,
      billing_email: billingEmail,
      city,
      zip_code,
      created_by,
      order_confirmation_email,
      test_account,
      pub_agreement,
      pub_date,
      integration_api_enabled,
      account_manager_id,
      is_active,
      company_type,
      document,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    test_account,
    pub_agreement,
    pub_date,
    company_name,
    registration_nr,
    phone,
    firstname,
    lastname,
    email,
    city,
    zip_code,
    order_confirmation_email,
    integration_api_enabled,
    account_manager_id,
    is_active,
    address,
    company_type,
    document,
  ]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);

        const response = await userService(
          "filter[type]=admin&filter[status]=active&filter[is_active]=1&per_page=100"
        );
        const { data: { data: users = [] } = {} } = response;
        const userDropDownOptions = users.map(({ name = "", id = "" }) => ({
          label: `${name}`,
          value: id,
        }));

        setUsers(userDropDownOptions);
      } catch (error) {
        showErrorToast(messages.text_no_data_found);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [id, showErrorToast, messages]);

  const handleSubmit = async values => {
    try {
      setIsLoading(true);

      const payload = { id, ...values };

      if (payload.registration_nr) {
        payload.registration_nr =
          payload.registration_nr?.replace(/-/g, "") ?? "";
      }

      if (typeof payload.invoice === "boolean") {
        payload.invoice = Number(payload.invoice);
      }

      const newCompanyInfo = { ...companyDetails, ...payload };

      delete newCompanyInfo.invoice;

      if (payload.document) {
        const formData = new FormData();

        formData.append("media", payload.document);
        formData.append("type", payload.document.type);
        formData.append("category", messages.customer_pub_document);

        const { data: fileData = {} } = await fileUploadService(formData);

        payload.pub_agreement_file_id = fileData.id || null;
        newCompanyInfo.document = fileData;
      }

      const formattedPayload = Object.keys(payload).reduce((acc, key) => {
        if (companyDetails[key] !== payload[key]) {
          acc[key] = payload[key];
        }

        return acc;
      }, {});

      await editCustomerInfoService(formattedPayload);

      updateCustomerData(newCompanyInfo);

      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Div mt={3} maxWidth="1110px !important">
      {isLoading && <ProgressSpinner />}

      <Div display="flex" flexDirection="column" width={1} mt={"-16px"}>
        <EditableFieldsCard
          title={messages.label_details}
          config={company}
          onSubmit={handleSubmit}
          validationSchema={NameSchema}
          isEditPermission={hasWriteAccessToCustomers}
          values={user}
          options={users}
          onChange={setSelectedManagers}
          optionFields={users}
          optionLabel={users}
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />

        <EditableFieldsCard
          title={messages.title_contact_person}
          config={contact}
          onSubmit={handleSubmit}
          validationSchema={ContactSchema}
          isEditPermission={hasWriteAccessToCustomers}
        />

        <EditableFieldsCard
          title={messages.Settings}
          config={settings}
          onSubmit={handleSubmit}
          validationSchema={SettingSchema}
          isEditPermission={hasWriteAccessToCustomers}
        />

        <EditableFieldsCard
          title={messages.pub}
          config={pub}
          onSubmit={handleSubmit}
          validationSchema={PubSchema}
          isEditPermission={hasWriteAccessToCustomers}
        />
      </Div>

      <Div mt={2} width={1}>
        <PrimaryButtonIconOutlined
          color="red"
          width={[1, 1, "250px", "250px"]}
          margin={3}
          label={messages.label_delete_customer}
          onClick={handleDeleteProfile}
          icon={<Icon mr={2} name="rubber" />}
        />

        {isDeleteDialogVisible && (
          <ConfirmationPopup
            title={messages.label_delete_customer}
            description={messages.customer_delete_message}
            onCancelClick={handleCancelDeleteProfile}
            onAcceptClick={handleDeleteSubmit}
          />
        )}
      </Div>
    </Div>
  );
};
CompanyInfo.propTypes = {
  customerData: PropTypes.node,
  id: PropTypes.number,
  updateCustomerData: PropTypes.func,
};
export default CompanyInfo;
