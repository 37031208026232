import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import updateSourceDataById from "@app/services/cases/updateSourceDataById";
import getDataSources from "@app/services/products/getDataSources";

import DataTable from "@components/DataTableV2/DataTable";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";
import { CASES_DATA_STATUS_TYPE, DATA_SOURCE_TYPE } from "@utils/enum";
import { unFlattenObject } from "@utils/utils";

import DataEditorDialog from "./components/DataEditorDialog";

const SEARCHABLE_FIELDS = ["id", "case_id"];

const SourceDataTab = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { case_id } = useParams();
  const { showSuccessToast, showErrorToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [dataSourceFilters, setDataSourceFilters] = useState([]);

  useEffect(() => {
    const fetchDataSource = async () => {
      try {
        setIsLoading(true);

        const { data: dataSources = [] } = await getDataSources();
        const formattedDataSources = dataSources.map(dataSource => ({
          name: messages[`datasource_${dataSource}`],
          code: dataSource,
        }));

        setDataSourceFilters(formattedDataSources);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDataSource();
  }, [messages, showErrorToast]);

  const hasWriteDataSourcesPermission = hasAllPermissions([
    AdminPermissions.AdminWriteDataSources,
  ]);

  const handleOpenDataDialog = event => {
    const { data } = unFlattenObject(event);
    setJsonData(data);
  };

  const handleUpdateStatus = async ({ id, status }) => {
    try {
      setIsLoading(true);

      const targetStatus =
        status === CASES_DATA_STATUS_TYPE.MANUAL ||
        status === CASES_DATA_STATUS_TYPE.PENDING
          ? CASES_DATA_STATUS_TYPE.COMPLETED
          : CASES_DATA_STATUS_TYPE.MANUAL;
      const payload = {
        status: targetStatus,
      };

      const { data: { source_data: sourceData = [] } = {} } =
        await updateSourceDataById(case_id, id, payload);
      const targetSourceData = sourceData.find(
        ({ id: sourceDataId }) => sourceDataId === id
      );

      if (!targetSourceData) {
        showErrorToast(messages.exception_error_message);

        return;
      }

      const { status: updatedStatus = "" } = targetSourceData;

      showSuccessToast(
        updatedStatus === CASES_DATA_STATUS_TYPE.MANUAL ||
          updatedStatus === CASES_DATA_STATUS_TYPE.IN_PROGRESS
          ? messages.label_changed_to_manual
          : messages.label_changed_to_completed
      );
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const formatChangeStatus = ({ status }) => {
    const shouldChangeToCompleted =
      status === CASES_DATA_STATUS_TYPE.MANUAL ||
      status === CASES_DATA_STATUS_TYPE.PENDING;

    return shouldChangeToCompleted
      ? messages.label_change_to_completed
      : messages.label_change_to_manual;
  };

  const formatChangeStatusIcon = ({ status }) => {
    const shouldChangeToCompleted =
      status === CASES_DATA_STATUS_TYPE.MANUAL ||
      status === CASES_DATA_STATUS_TYPE.PENDING;

    return shouldChangeToCompleted ? "icon-tick-mark" : "icon-pen";
  };

  const handleRedirectToEmailsDialog = ({ id }) =>
    ROUTES.ADMIN_CASE_EMAIL_THREAD.URL.replace(":case_id", case_id).replace(
      ":data_source_id",
      id
    );

  const handleRedirectToCrimesDialog = ({ id }) =>
    ROUTES.ADMIN_CASE_CRIMES.URL.replace(":case_id", case_id).replace(
      ":data_source_id",
      id
    );

  const handleFormatStatus = value => {
    const statusTranslationsByValue = {
      [CASES_DATA_STATUS_TYPE.PENDING]: messages.label_cases_status_pending,
      [CASES_DATA_STATUS_TYPE.MANUAL]: messages.label_manual,
      [CASES_DATA_STATUS_TYPE.COMPLETED]:
        messages[CASES_DATA_STATUS_TYPE.COMPLETED],
    };

    return statusTranslationsByValue[value];
  };

  const formatCrimesLabel = ({ status }) => {
    return status === CASES_DATA_STATUS_TYPE.COMPLETED
      ? messages.label_view_crimes
      : messages.label_edit_add_crimes;
  };

  const isCourtDataSource = type => {
    const courtTypes = [
      DATA_SOURCE_TYPE.COURT_SMALL,
      DATA_SOURCE_TYPE.COURT_MEDIUM,
      DATA_SOURCE_TYPE.COURT_LARGE,
      DATA_SOURCE_TYPE.COURT,
    ];

    return courtTypes.includes(type);
  };

  const shouldEmailsActionBeHidden = ({ type }) => {
    return !isCourtDataSource(type);
  };

  const shouldCrimesActionBeHidden = ({ type }) => {
    const isCourtType =
      isCourtDataSource(type) ||
      type === DATA_SOURCE_TYPE.VERIFERA_PERSON_INFORMATION_ROLE_ANALYSIS;
    return !isCourtType || !hasWriteDataSourcesPermission;
  };

  const shouldStatusActionBeHidden = () => !hasWriteDataSourcesPermission;

  const formatSourceDataType = (value, db_fields) => {
    const courtName = db_fields["court.court_name"];

    if (value === "court") {
      return messages[`datasource_${value}`] + ` (${courtName}) ` || "-";
    }

    return messages[`datasource_${value}`] || "-";
  };

  const config = {
    header: {
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.order_filter_description,
        },
      ],
    },
    enable_search: true,
    enable_query_builder: true,
    backend_querying: true,
    is_filter_options_updated: dataSourceFilters.length > 1,
    api: {
      resource: `/source_data`,
      method: "GET",
      search_fields: SEARCHABLE_FIELDS,
      params: [
        ["include", "court"],
        ["filter[case_id]", case_id],
      ],
    },
    enable_filter: true,
    filters: [
      {
        title: messages.label_cases_data_source,
        id: "filter[type]",
        type: "multiselect",
        options: [
          {
            name: "filter[type]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[type]",
            options: dataSourceFilters,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "checkbox",
        options: [
          {
            label: messages.label_cases_status_pending,
            value: CASES_DATA_STATUS_TYPE.PENDING,
            checked: false,
          },
          {
            label: messages.label_manual,
            value: CASES_DATA_STATUS_TYPE.MANUAL,
            checked: false,
          },
          {
            label: messages[CASES_DATA_STATUS_TYPE.COMPLETED],
            value: CASES_DATA_STATUS_TYPE.COMPLETED,
            checked: false,
          },
        ],
      },
    ],
    columns: [
      {
        db_field: "id",
        title: "label_id",
        type: "id",
        width: "11%",
        sortable: true,
        plainText: true,
      },
      {
        db_field: "type",
        title: "label_cases_data_source",
        type: "text",
        formatter: formatSourceDataType,
        width: "34%",
        sortable: true,
      },
      {
        db_field: "case_id",
        title: "label_case_id",
        type: "id",
        width: "22%",
        sortable: true,
        plainText: true,
      },
      {
        db_field: "status",
        title: "label_status",
        type: "enum",
        sortable: true,
        formatter: handleFormatStatus,
      },
      {
        type: "actions",
        width: "5%",
        actions: [
          {
            label: messages.label_show_data,
            icon: "icon-file-download",
            onClick: handleOpenDataDialog,
          },
          {
            label: messages.label_change_to_manual,
            iconSelector: formatChangeStatusIcon,
            onClick: handleUpdateStatus,
            formatter: formatChangeStatus,
            shouldHideAction: shouldStatusActionBeHidden,
          },
          {
            label: messages.label_edit_add_crimes,
            type: "link",
            icon: "icon-menu-report",
            formatter: formatCrimesLabel,
            href: handleRedirectToCrimesDialog,
            shouldHideAction: shouldCrimesActionBeHidden,
          },
          {
            label: messages.label_view_emails,
            type: "link",
            icon: "icon-mail",
            href: handleRedirectToEmailsDialog,
            shouldHideAction: shouldEmailsActionBeHidden,
          },
        ],
      },
    ],
  };

  return (
    <>
      {isLoading && <ProgressSpinner />}

      {jsonData && (
        <DataEditorDialog
          data={jsonData}
          onClose={() => {
            setJsonData(null);
          }}
        />
      )}

      <DataTable config={config} />
    </>
  );
};

export default SourceDataTab;
