import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import DataTable from "@components/DataTableV2/DataTable";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";
import { USERS, ROUTES } from "@utils/constant";
import { getUserRoleTranslationKey, fetchUserStatus } from "@utils/utils";

const searchableColumns = [
  "id",
  "firstname",
  "lastname",
  "email",
  "customer.customer_name",
];

const CustomerListTab = ({
  roleFilters,
  isFilterValuesLoaded,
  reloadList,
  onAddUser,
  onEditUser,
  onDeleteUser,
  onResendInvitation,
}) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();

  const hasWriteUsersPermission = hasAllPermissions([
    AdminPermissions.AdminWriteUsers,
  ]);

  const shouldHideResendButton = ({ email_verified_at }) => !!email_verified_at;

  const redirectToCustomerPage = ({ customer_id }) =>
    ROUTES.CUSTOMER_DETAILS.URL.replace(":id", customer_id);

  const formatRole = (data) => {
    const [values] = data;
    return values?.name
      ? messages[getUserRoleTranslationKey(values?.name)]
      : "-";
  };

  const config = {
    header: {
      title: "title_customers",
      actions: [
        {
          id: "new-admin",
          type: "button",
          label: messages.label_new_user,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasWriteUsersPermission,
          onClick: onAddUser,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
        },
      ],
    },
    is_filter_options_updated: isFilterValuesLoaded,
    enable_csv_download: true,
    csv_filename: "users.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_role,
        id: "filter[roles.id]",
        type: "checkbox",
        options: roleFilters,
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_users_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    columns: [
      {
        title: "user",
        db_field: "name",
        type: "text",
        sortable: true,
        sortField: "firstname",
      },
      {
        title: "title_customer",
        type: "link",
        db_field: "customer.customer_name",
        href: redirectToCustomerPage,
        width: "17%",
        sortable: true,
      },
      {
        title: "label_email",
        db_field: "email",
        type: "text",
        width: "17%",
        sortable: true,
      },
      {
        title: "role_label",
        db_field: "roles",
        type: "text",
        formatter: formatRole,
        width: "13%",
      },
      {
        title: "label_telephone_short",
        db_field: "phone",
        type: "text",
        width: "120px",
        sortable: true,
      },
      {
        title: "label_status",
        db_field: "email_verified_at",
        type: "text",
        formatter: fetchUserStatus,
        sortable: true,
        width: "120px",
      },
      {
        title: "last_visit_label",
        db_field: "last_login_at",
        type: "dateTime",
        sortable: true,
      },
      {
        type: "actions",
        width: "58px",
        isHidden: !hasWriteUsersPermission,
        actions: [
          {
            label: messages.label_edit,
            icon: "icon-pen",
            type: "button",
            onClick: onEditUser,
          },
          {
            label: messages.label_resend_verification,
            icon: "icon-pen",
            type: "button",
            onClick: onResendInvitation,
            shouldHideAction: shouldHideResendButton,
          },
          {
            label: messages.label_delete,
            icon: "icon-rubber",
            onClick: onDeleteUser,
            type: "button",
          },
        ],
      },
    ],
    api: {
      resource: `/${USERS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["include", ["roles", "roles.permissions", "permissions", "customer"]],
        ["filter[type]", "customer"],
      ],
    },
  };

  return <DataTable config={config} resetData={reloadList} />;
};
CustomerListTab.propTypes = {
  roleFilters: PropTypes.array,
  isFilterValuesLoaded: PropTypes.bool,
  reloadList: PropTypes.bool,
  onAddUser: PropTypes.func,
  onEditUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onResendInvitation: PropTypes.func,
};
export default CustomerListTab;
