import React, { useCallback } from "react";
import Div from "@components/Div";
import { H4, H3, H1 } from "@components/Heading";
import Section from "@components/Section";
import Container from "@components/Container";
import Icon from "@components/Icon";
import { useIntl } from "react-intl";
import { consentheadingProps } from "@components/Report/LegacyReport/config";
import FAQSection from "./FAQSection";
import { Text, TextMediumWeight, EllipseIconText } from "@components/Text";
import { Fieldset } from "@components/Fieldset";
import { EllipseIcon } from "@components/CountTag";
import PropTypes from "prop-types";
import { EXPIRY_DAYS } from "@utils/constant";
import { calculateDateExpiryDays } from "@utils/common";
import faq from "./faq.json";
import styled from "styled-components";

import BankIDLogin from "../BankidComponent/BankIDLogin";

const StyledFieldset = styled(Fieldset)`
  .p-fieldset-content {
    min-height: 130px !important;
  }
`;

const customIcon = (id) => (
  <EllipseIcon>
    <EllipseIconText>{id + 1}</EllipseIconText>
  </EllipseIcon>
);

const PendingConsent = ({
  report = {},
  companyName = "",
  onHandleUpdate = () => {},
  onError,
}) => {
  const { created_at, personal_number } = report;

  const { messages } = useIntl();
  const { title } = consentheadingProps;
  const expiryDays = calculateDateExpiryDays(created_at, EXPIRY_DAYS);
  const handleSuccess = useCallback(() => {
    onHandleUpdate("in-progress");
  }, [onHandleUpdate]);

  const handleError = useCallback(
    (error) => {
      onError(error);
    },
    [onError],
  );

  const items = [
    {
      title: messages.premissive_enforcement,
      content: [
        {
          description: messages.description_premissive_enforcement,
          list: [],
        },
      ],
    },
    {
      title: messages.title_take_part,
      content: [
        {
          description: messages.description_take_part,
          list: [],
        },
      ],
    },
    {
      title: messages.title_premissive_sharing,
      content: [
        {
          description: messages.byline_premissive_sharing,
          list: [],
        },
      ],
    },
  ];

  const renderListItems = (listItems) => {
    return listItems.map((listItem) => {
      const uniqueKey = `listVal-${listItem}`;
      const isFlex = listItems.indexOf(listItem) === 3;
      const isBaseline = isFlex;
      const paddingBottom = listItems.indexOf(listItem) === 1 ? "16px" : "";
      const display = isFlex ? "flex" : "";
      const alignItems = isBaseline ? "baseline" : "";

      return (
        <li
          key={uniqueKey}
          style={{
            paddingBottom,
            display,
            alignItems,
          }}
        >
          <Icon
            color={"var(--blue-dark)"}
            name="ellipse"
            mr={1}
            fontSize={"var(--fs-icon)"}
          />
          <Text
            display={
              listItems.indexOf(listItem) === 1 ? "contents" : "inline-block"
            }
            pb={3}
          >
            {listItem}
          </Text>
        </li>
      );
    });
  };

  const renderDescriptionContent = (descriptionContent) => (
    <Div key={`descContent-${descriptionContent.description}`}>
      <Text mb={3} display="block">
        {descriptionContent.description}
      </Text>
      {descriptionContent.list.map((listItem) => (
        <React.Fragment key={`listItem-${listItem.title}`}>
          <Text my={3} display="block">
            {listItem.title}
          </Text>
          <ul>{renderListItems(listItem.values)}</ul>
        </React.Fragment>
      ))}
    </Div>
  );

  const renderTitleContent = (titleContent, index) => (
    <Div
      key={`titleContent-${titleContent.title}`}
      mt={3}
      flex={["100%", "100%", "100%", "32.33%"]}
      py={2}
      pr={[0, 0, 4]}
    >
      <StyledFieldset legend={customIcon(index)} width={[1]} p={3}>
        <Div px={3}>
          <H3 mb={3}>{titleContent.title}</H3>
          {titleContent.content.map(renderDescriptionContent)}
        </Div>
      </StyledFieldset>
    </Div>
  );

  return (
    <>
      <Section p={"16px !important"} bg={["white"]}>
        <Container bg={"white !important"} py={"0 !important"}>
          <Div
            display={["flex", "flex", "flex", "flex"]}
            width={1}
            alignItems="center"
          >
            <Div
              mt={4}
              width={1}
              display="flex"
              flexDirection={["column", "row"]}
              alignItems={["start", "center"]}
              justifyContent="space-between"
            >
              <H1 my={3}>{messages[title]}</H1>
              <H4>
                {expiryDays < 1
                  ? messages.consent_label_expired
                  : `${expiryDays} ${messages.consent_label_days_for_consent}`}
              </H4>
            </Div>
          </Div>
          <Div my={20} display="block">
            <TextMediumWeight>
              {companyName} {messages.desc_pending_report_summary}
            </TextMediumWeight>
          </Div>
          <Div
            display="flex"
            justifyContenitemst="center"
            flexWrap="wrap"
            flexDirection={["column", "column", "column", "row"]}
          >
            {items.map((titleContent, index) =>
              renderTitleContent(titleContent, index),
            )}
          </Div>
        </Container>
      </Section>
      <Section p={[0, 0, 0, "16px !important"]} bg={["white"]}>
        <Container py={"0 !important"}>
          <Div
            bg="var(--blue-lightest)"
            mx={[0, 0, 0, 15]}
            mt={40}
            px={[0, 0, 0, 15]}
            py={30}
          >
            <Div display="flex" flexDirection="column" gridGap={15} px={20}>
              <H3>{messages.label_BankID.toUpperCase()}</H3>
              <Text>{messages.message_bankID}</Text>
              <BankIDLogin
                ssn={personal_number}
                onSuccess={handleSuccess}
                onError={handleError}
              />
            </Div>
          </Div>
        </Container>
      </Section>
      <FAQSection
        title={messages.FAQ_title}
        subtitle="LOREM IPSUM DOLOR SIT AMET, CONSETETUR"
        faqArr={faq}
      />
    </>
  );
};

PendingConsent.propTypes = {
  report: PropTypes.object,
  companyName: PropTypes.string,
  onHandleUpdate: PropTypes.func,
  onError: PropTypes.func,
};

export default PendingConsent;
