import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import Div from "@components/Div";

import UserContainer from "@layout/UserContainer";

import { ROUTES, SSNNUMBER } from "@utils/constant";

import SSNSearch from "@pages/shared/CreateOrderWizard/SSNSearch";

const Search = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const [ssnNumber, setSsnNumber] = useState([]);

  const handleOpenWizardDialog = () => {
    const query = new URLSearchParams();

    query.set(SSNNUMBER, ssnNumber);

    history.push({
      pathname: ROUTES.WIZARD_SEARCH.URL,
      search: query.toString(),
    });
  };

  const headerConfig = {
    title: messages.search_text,
  };

  return (
    <UserContainer config={headerConfig} hideGdprInfo={true}>
      <Div mt={4}>
        <SSNSearch
          ssnNumbers={ssnNumber}
          onSearchSSN={handleOpenWizardDialog}
          onChange={setSsnNumber}
          isAuthenticated={true}
        />
      </Div>
    </UserContainer>
  );
};

export default Search;
