import React from "react";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";
import Container from "@components/Container";
import Div from "@components/Div";
import Breadcrumb from "@components/Breadcrumb";

import { ROUTES } from "@utils/constant";
import Timelog from "./Timelog";
import CandidateOrderDetails from "@pages/shared/orderDetails/CandidateOrderDetails";
import OrderInfo from "./OrderInfo";
import OrderBillingCard from "./OrderBillingCard";
import useAuthorization from "@hooks/useAuthorization";
import { CustomerPermissions, AdminPermissions } from "@src/enum/Permissions";

const OrderDetails = ({
  orderSummaryProps,
  redirectTo = ROUTES.ORDERS_LIST.URL,
  isCustomer,
  onUpdateOrderSummaryProps,
}) => {
  const {
    showTimeLog = isCustomer,
    order_id: orderNum = "",
    watchList = {},
    reports = [],
    assignments = [],
    order_items: orderItems = [],
    payments = [],
    status: orderStatus,
  } = orderSummaryProps;

  const { messages } = useIntl();
  const { hasAllPermissions, isAdmin } = useAuthorization();

  const isBillingPermission = isAdmin
    ? hasAllPermissions([AdminPermissions.AdminReadBilling])
    : hasAllPermissions([CustomerPermissions.CustomerReadCompanyInformation]);

  const paymentMethodInvoice = payments.some(
    (payment) =>
      payment.payment_method_id === 2 || payment.payment_method_id === 5,
  );

  const breadCrumbItems = [
    { label: messages.my_profile },
    { label: messages.label_order_page, url: redirectTo },
    { label: `#${orderNum}` },
  ];

  const [report = {}] = reports;

  return (
    <Div mb={40}>
      <Div>
        <Breadcrumb p={0} items={breadCrumbItems} />
      </Div>
      <Container m={"0 !important"}>
        <Div pb={2} pt={"8px"}>
          <Div my={3} py={2}>
            <OrderInfo
              data={orderSummaryProps}
              isWatchList={watchList && watchList !== null}
            />
          </Div>
        </Div>
        <Div>
          <CandidateOrderDetails
            orderStatus={orderStatus}
            orderItems={orderItems}
            isOrderDetails={true}
            assignments={assignments}
          />
        </Div>

        {paymentMethodInvoice && isBillingPermission && (
          <Div mt={2}>
            <OrderBillingCard
              orderSummaryProps={orderSummaryProps}
              onUpdateOrderSummaryProps={onUpdateOrderSummaryProps}
            />
          </Div>
        )}

        {showTimeLog && (
          <Timelog logs={report.logs} ssn={report.personal_number} />
        )}
      </Container>
    </Div>
  );
};

OrderDetails.propTypes = {
  orderSummaryProps: Proptypes.array,
  redirectTo: Proptypes.string,
  showTimeLog: Proptypes.bool,
  isCustomer: Proptypes.bool,
  isAdmin: Proptypes.bool,
  onUpdateOrderSummaryProps: Proptypes.func,
};

export default OrderDetails;
