import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import Div from "@components/Div";
import Proptypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import ProgressSpinner from "@components/ProgressSpinner";
import updateSubscription from "@app/services/subscription/updateSubscription";
import terminateSubscription from "@app/services/subscription/terminateSubscription";
import { useToast } from "@hooks/useToast";
import Currency from "@components/Currency";
import customerDetailsService from "@app/services/customers/customerDetailsService";
import DataTable from "@components/DataTableV2/DataTable";
import {
  checkUserPermission,
  loadServicesData,
} from "@utils/common";
import {
  ROUTES,
  INPUT,
  SPECIAL_PACKAGE,
  ADMIN,
  SUBSCRIPTION_STATUS,
} from "@utils/constant";
import SubscriptionUpdateValidation from "@pages/auth/schema/SpecialSubscriptionValidation";
import Breadcrumb from "@components/Breadcrumb";
import AdminContainer from "@layout/AdminContainer";
import EditableFieldsCard from "@components/EditableFieldsCard";
import { FORM_INPUT_TYPE, RENEWEL_MODE_TYPE } from "@utils/enum";
import ActivityLogDetails from "../activityLogs/Details";
import { formatDate } from "@utils/utils";
import { H2 } from "@components/Heading";
import getSubscription from "@app/services/subscription/getSubscription";

const getCustomerConfig = data => {
  const configuration = {
    details: [
      {
        key: "company_name",
        translationKey: "title_customer",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.company_name,
      },
      {
        key: "registration_nr",
        translationKey: "label_company_number",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.registration_nr,
      },
      {
        key: "name",
        translationKey: "user",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.name,
      },
    ],
  };
  return configuration;
};

const getPackageConfig = (data, packages) => {
  const packageOptions = packages.map(o => ({
    label: o?.name,
    value: o?.id,
    available_credits: o?.credits,
    total_credits: o?.credits,
  }));
  const configuration = {
    packages: [
      {
        key: "product_id",
        translationKey: "package_label",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: packageOptions,
        selectorField: "label",
        value: Number(data?.product_id),
        fullWidth: true,
        handleChange: (e, formikValues) => {
          const { value } = e;
          const { values, setValues } = formikValues;
          const product = packageOptions.find(o => o?.value == value);
          const { available_credits, total_credits } = product;
          setValues({
            ...values,
            product_id: value,
            available_credits,
            total_credits,
          });
        },
      },
      {
        key: "credits",
        editable: false,
        translationKey: "total_credits",
        type: FORM_INPUT_TYPE.INPUTNUMBER,
        value: `${data?.available_credits}/${data?.total_credits}`,
      },
      {
        key: "total_credits",
        translationKey: "total_credits",
        type: FORM_INPUT_TYPE.INPUTNUMBER,
        textType: FORM_INPUT_TYPE.INPUTNUMBER,
        value: data?.total_credits,
        isHidden: true,
        fullWidth: true,
        isDisabled: values => {
          const { label = "" } = packageOptions.find(
            o => o?.value === values?.product_id
          );
          return label !== SPECIAL_PACKAGE;
        },
      },
      {
        key: "available_credits",
        translationKey: "text_available_credits",
        type: FORM_INPUT_TYPE.INPUTNUMBER,
        textType: FORM_INPUT_TYPE.INPUTNUMBER,
        value: data?.available_credits,
        isHidden: true,
        fullWidth: true,
      },

      {
        key: "price_in_currency",
        translationKey: "watchlist_label_price",
        type: FORM_INPUT_TYPE.INPUTNUMBER,
        textType: FORM_INPUT_TYPE.INPUTNUMBER,
        value: <Currency value={data?.price} />,
        fullWidth: true,
        editable: false,
      },
      {
        key: "price",
        translationKey: "price",
        type: FORM_INPUT_TYPE.INPUTNUMBER,
        textType: FORM_INPUT_TYPE.INPUTNUMBER,
        value: data?.price | 0,
        fullWidth: true,
        isHidden: true,
      },
    ],
    settings: [
      {
        key: "status",
        translationKey: "label_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="active" />,
            value: SUBSCRIPTION_STATUS.ACTIVE,
          },
          {
            label: <FormattedMessage id="stopped_label" />,
            value: SUBSCRIPTION_STATUS.STOPPED,
          },
          {
            label: <FormattedMessage id="terminated_label" />,
            value: SUBSCRIPTION_STATUS.TERMINATED,
          },
        ],
        selectorField: "label",
        value: data.status,
        fullWidth: true,
      },
      {
        key: "renewals",
        translationKey: "renewel",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="on" />,
            value: RENEWEL_MODE_TYPE.ON,
          },
          {
            label: <FormattedMessage id="off" />,
            value: RENEWEL_MODE_TYPE.OFF,
          },
        ],
        selectorField: "label",
        value: data.renewals,
        fullWidth: true,
        isHidden: data?.status === SUBSCRIPTION_STATUS.TERMINATED,
        show: values => {
          return values?.status !== SUBSCRIPTION_STATUS.TERMINATED;
        },
      },
      {
        key: "renewel_date",
        translationKey: "renewel_date",
        type: FORM_INPUT_TYPE.CALENDAR,
        value: data.renewal_date,
        fullWidth: true,
        isHidden:
          !data?.renewals || data?.status === SUBSCRIPTION_STATUS.TERMINATED,
        show: values => {
          return (
            !!values?.renewals &&
            values?.status !== SUBSCRIPTION_STATUS.TERMINATED
          );
        },
      },
    ],
  };
  return configuration;
};

export const SubscriptionDetails = ({ isInCustomerDetailsPage = false, subscriptionData = {} }) => {
  const { messages } = useIntl();
  const loginUserInfo = useSelector(state => state.authReducer.userInfo);
  const [subscriptionDetail, setSubscriptionDetail] = useState(false);
  const [getActivityLog, setGetActivityLog] = useState(false);
  const [customerDetail, setCustomerDetail] = useState(false);
  const history = useHistory();
  const { id: subscritionId = "" } = subscriptionData;
  const { id, subscription_id = subscritionId } = useParams();
  const [subscriptionPackage, setSubscriptionPackage] = useState({});
  const [selectedPackage, setSelectedPackage] = useState(false);
  const [subsPackages, setSubsPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();
  const [selectedActivity, setSelectedActivity] = useState("");
  const [showActivityLog, setShowActivityLog] = useState(false);
  const [reloadDetails, setReloadDetails] = useState(false);

  //* INPUT VALUE Const is here */
  const { AVAILABLE_ANALYSIS, TOTAL_ANALYSIS, PRICE } = INPUT.NAME;

  if (subscriptionPackage?.name === SPECIAL_PACKAGE) {
    SubscriptionUpdateValidation.concat(
      SubscriptionUpdateValidation.pick([AVAILABLE_ANALYSIS])
    )
      .concat(SubscriptionUpdateValidation.pick([TOTAL_ANALYSIS]))
      .concat(SubscriptionUpdateValidation.pick([PRICE]));
  }

  const breadCrumbItems = [
    {
      label: messages.subscriptions_title,
      url: ROUTES.SUBSCRIPTION_LIST.URL,
    },
    {
      label: `#${subscriptionDetail?.id}`,
    },
  ];

  useEffect(() => {
    if (!subscriptionDetail || reloadDetails) {
      const customerInfo = { customer: { id: id } };
      (async () => {
        setIsLoading(true);
        checkUserPermission(loginUserInfo, history);
        const [subscriptionResponse, packageList] = await Promise.all([
          getSubscription(
            isInCustomerDetailsPage ? customerInfo?.id : "",
            `&filter[id]=${subscription_id}&include=auditLogs,customer`
          ),
          loadServicesData(id),
        ]);
        const { data: { data = [] } = {} } = subscriptionResponse;
        const [detail = {}] = data;
        const { product_id } = detail;
        const { name = "" } = packageList.find(o => o?.id == product_id) || {};
        setSubscriptionDetail({ ...detail, name });
        setSubsPackages(packageList);
        setSelectedPackage(product_id);
        setIsLoading(false);
        setReloadDetails(false);
        const { audit_logs = [] } = detail || {};
        setGetActivityLog(audit_logs);
      })();
    }
  }, [
    loginUserInfo,
    history,
    id,
    subscriptionDetail,
    reloadDetails,
    isInCustomerDetailsPage,
    subscription_id,
  ]);

  useEffect(() => {
    if (selectedPackage && subsPackages.length > 0) {
      setSubscriptionPackage(
        subsPackages.find(service => service.id === parseInt(selectedPackage))
      );
    }
  }, [selectedPackage, subsPackages]);
  useEffect(() => {
    if (!customerDetail) {
      const getCustomer = async () => {
        try {
          const { data: customerData = {} } = await customerDetailsService(id);
          const { data } = customerData;
          setCustomerDetail(data);
        } catch (e) {
          //catch the error
        }
      };
      getCustomer();
    }
  }, [customerDetail, id]);

  const headerConfig = {
    title: `${messages.subscription} #${subscriptionDetail.id}`,
  };

  const { details } = getCustomerConfig(customerDetail);
  const { packages, settings } = getPackageConfig(
    subscriptionDetail,
    subsPackages
  );

  const handleUpdateServiceStatus = async data => {
    try {
      setIsLoading(true);
      if (
        data?.status === SUBSCRIPTION_STATUS.TERMINATED &&
        subscriptionDetail?.status !== SUBSCRIPTION_STATUS.TERMINATED
      ) {
        await terminateSubscription(
          subscriptionDetail.id,
          subscriptionDetail.status
        );
      } else {
        await updateSubscription(
          {
            product_id: subscriptionDetail.product_id,
            customer_id: customerDetail.customer_id,
            subscription_id: subscriptionDetail.id,
            signed_by: ADMIN + " " + loginUserInfo.email,
            status: data?.status,
            renewals: data?.renewals ? true : false,
            renewal_date: data?.renewals ? formatDate(data?.renewel_date) : "",
            price: Number(subscriptionDetail?.price),
            available_credits: subscriptionDetail.available_credits,
            total_credits: subscriptionDetail?.total_credits,
          },
          subscriptionDetail.id
        );
      }
      setReloadDetails(true);
      setIsLoading(false);
      showSuccessToast(messages.subscription_updated_success_message);
    } catch (e) {
      setIsLoading(false);
      showErrorToast(messages.error_try_later);
    }
  };

  const handleUpdateSubscriptionPackage = async values => {
    const {
      product_id: packageName,
      price,
      available_credits,
      total_credits,
    } = values;
    const subscriptionPayload = {
      product_id: packageName,
      customer_id: customerDetail.customer_id,
      subscription_id: subscriptionDetail.id,
      signed_by: ADMIN + " " + loginUserInfo.email,
      status: subscriptionDetail?.status,
      price: Number(price),
      available_credits: available_credits,
      total_credits: total_credits,
    };
    if (subscriptionPackage?.name === SPECIAL_PACKAGE) {
      subscriptionPayload.price = price;
      subscriptionPayload.available_credits = available_credits;
      subscriptionPayload.total_credits = total_credits;
    }
    try {
      setIsLoading(true);
      await updateSubscription(subscriptionPayload, subscriptionDetail.id);
      setIsLoading(false);
      setReloadDetails(true);
      showSuccessToast(messages.subscription_updated_success_message);
    } catch (err) {
      setIsLoading(false);
      showErrorToast(messages.error_try_later);
    }
  };

  const handleActivityLogDetails = (values = "") => {
    setSelectedActivity(showActivityLog ? "" : values);
    setShowActivityLog(!showActivityLog);
  };

  const activityConfig = {
    headerActions: [],
    no_records_message: "no_payment_received_yet",
    default_sort: {
      field: "created_at",
      order: "-1",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
      },
      {
        title: "label_purchaser",
        db_field: "user",
        type: "text",
        formatter: value => value?.name || "-",
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
      },
      {
        title: "type_label",
        db_field: "event",
        type: "enum",
      },
      {
        title: "model",
        db_field: "auditable_type",
        type: "text",
        formatter: value => {
          const model = value?.split("App\\Models\\")?.[1];
          return model ? messages[model.toLowerCase()] : "-";
        },
      },
      {
        type: "actions",
        width: "50px",
        icon: "menu-report",
        actions: [
          {
            icon: "menu-report",
            type: "button",
            onClick: handleActivityLogDetails,
          },
        ],
      },
    ],
    backend_querying: false,
    enable_pagination: true,
  };

  const renderSubscription = () => {
    return isLoading ? (
      <ProgressSpinner />
    ) : (
      <Div
        maxWidth={[1, 1, 1, 1110]}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
      >
        <EditableFieldsCard
          title={messages.title_customer}
          config={details}
          data={customerDetail}
          isEditPermission={false}
        />
        <EditableFieldsCard
          title={messages.package_label}
          config={packages}
          data={subscriptionDetail}
          onSubmit={handleUpdateSubscriptionPackage}
          isEditPermission={true}
        />
        <EditableFieldsCard
          title={messages.Settings}
          config={settings}
          data={subscriptionDetail}
          onSubmit={handleUpdateServiceStatus}
          isEditPermission={true}
        />
        <H2 py={2}>{messages.subscription_activity_log}</H2>
        <DataTable
          config={{ ...activityConfig, static_data: getActivityLog }}
        />
      </Div>
    );
  };

  return (
    <>
      {isInCustomerDetailsPage ? (
        renderSubscription()
      ) : (
        <AdminContainer config={headerConfig}>
          <Breadcrumb items={breadCrumbItems} p={0} pb={[1, 1, 1, 10]} />
          {renderSubscription()}
        </AdminContainer>
      )}
      {showActivityLog && (
        <ActivityLogDetails
          data={selectedActivity}
          handleClose={handleActivityLogDetails}
        />
      )}
    </>
  );
};

SubscriptionDetails.propTypes = {
  isInCustomerDetailsPage: Proptypes.bool,
  subscriptionData: Proptypes.object,
};

export default SubscriptionDetails;
