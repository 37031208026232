import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import styled from "styled-components";

import { PrimaryButtonOutlined } from "@components/Button";
import Container from "@components/Container";

import Div from "@components/Div";
import { H1 } from "@components/Heading";
import Icon from "@components/Icon";
import Section from "@components/Section";
import { Text } from "@components/Text";

import Config from "@src/config";

import { REPORTS } from "@utils/constant";

import { headingProps } from "./config";
import PrintReportDialog from "./PrintReportDialog";

const Button = styled(PrimaryButtonOutlined)`
  white-space: nowrap;
  padding-right: 32px;
  padding-left: 32px;
`;

const Heading = ({
  reportId = "",
  onPDFGenerationStart,
  onPDFGenerationFinish,
}) => {
  const { messages } = useIntl();

  const [isPrintReportDialogVisible, setIsPrintReportDialogVisible] =
    useState(false);

  const { subTitle } = headingProps;

  const downloadUrl = `${Config.BASE_URL}${REPORTS}/${reportId}/report-pdf`;

  const handleOpenPrintDialog = () => {
    setIsPrintReportDialogVisible(true);
  };

  const handleClosePrintDialog = () => {
    setIsPrintReportDialogVisible(false);

    onPDFGenerationFinish();
  };

  const handleConfirmPDFCreation = () => {
    onPDFGenerationStart();
  };

  return (
    <Section p="0px !important" bg="white" mb={3}>
      {isPrintReportDialogVisible && (
        <PrintReportDialog
          downloadUrl={downloadUrl}
          onConfirm={handleConfirmPDFCreation}
          onClose={handleClosePrintDialog}
        />
      )}

      <Container bg={"white !important"} py={"0 !important"}>
        <Div
          display="flex"
          width={1}
          py={4}
          flexDirection={["column", "column", "row", "row"]}
          alignItems={["flex-start", "flex-start", "center", "center"]}
          justifyContent="space-between"
          gridGap={[3, 3, 0, 0]}
        >
          <H1>{messages[subTitle]}</H1>

          <Button width="auto" onClick={handleOpenPrintDialog}>
            <Icon alt="icon" name="download-assignment" mr={2} />
            {messages.label_download_as_pdf}
          </Button>
        </Div>

        <Div p={3} bg={"var(--yellow-light)"} display="block">
          <Text
            fontWeight="var(--regular-weight)"
            fontSize={[
              "var(--fs-text-small) !important",
              "var(--fs-text-small) !important",
              "var(--fs-text-secondary) !important",
              "var(--fs-text-secondary) !important",
            ]}
          >
            {messages.desc_report_summary}
          </Text>
        </Div>
      </Container>
    </Section>
  );
};

Heading.propTypes = {
  reportId: PropTypes.string,
  onPDFGenerationStart: PropTypes.func,
  onPDFGenerationFinish: PropTypes.func,
};

export default Heading;
