import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { TextLarge, TextLargeSemiBoldWeight, Text } from "@components/Text";
import Div from "@components/Div";

function formatGrade(input) {
  // Convert the input string to a number
  const number = parseInt(input, 10);

  // Check if the number is positive and not zero, prepend '+' sign
  if (number > 0) {
    return `+${number}`;
  }

  // For zero or negative numbers, just return the number as is
  return number.toString();
}

const StatementScale = ({ isPrintable = false, config = {} }) => {
  const { messages } = useIntl();
  const { description, grade, footer } = config;

  const constructGrades = (arr = []) =>
    arr.map((value, index) => (
      <Div key={index} display="flex" mb={isPrintable ? 2 : 24}>
        <Div
          display="block"
          width={isPrintable ? 10 : [20, 20, 20, 30]}
          maxHeight={[
            "-webkit-fill-available",
            "-webkit-fill-available",
            "-webkit-fill-available",
            116,
          ]}
          px={isPrintable ? "5px" : [10, 10, 10, 15]}
          bg={`var(--${value.bgColor})`}
        />
        <Div
          display={isPrintable ? "flex" : ["block", "block", "block", "flex"]}
          ml={isPrintable ? 0 : [10, 10, 10, 20]}
          width={[1]}
        >
          <Div
            display="flex"
            mb={isPrintable ? 0 : [10, 10, 10, 0]}
            mr={isPrintable ? 15 : [0, 0, 0, 24]}
            p={0}
            width={isPrintable ? 130 : [1, 1, 1, 130]}
            maxHeight={
              isPrintable
                ? "inherit"
                : [
                    "-webkit-fill-available",
                    "-webkit-fill-available",
                    "-webkit-fill-available",
                    116,
                  ]
            }
            alignItems="center"
            backgroundColor={
              isPrintable
                ? "var(--turquoise-light)"
                : ["none", "none", "none", "var(--turquoise-light)"]
            }
          >
            <Div
              width={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight={isPrintable ? 70 : [60, 60, 60, 116]}
              backgroundColor={
                isPrintable
                  ? "none"
                  : [
                      "var(--turquoise-light)",
                      "var(--turquoise-light)",
                      "var(--turquoise-light)",
                      "none",
                    ]
              }
              mr={0}
              px={3}
            >
              <TextLargeSemiBoldWeight
                width={1}
                px={0}
                py={[25, 25, 25, 0]}
                textAlign={["center", "center", "start", "start"]}
                lineHeight={
                  isPrintable
                    ? "var(--fs-h5) !important"
                    : "var(--lh-text-secondary)"
                }
                fontSize={isPrintable && "var(--fs-text-s) !important"}
              >
                {messages[value.title]} {formatGrade(value.grades)}
              </TextLargeSemiBoldWeight>
            </Div>
          </Div>

          <Div
            display="flex"
            flexDirection="column"
            backgroundColor={"var(--turquoise-light)"}
            justifyContent="center"
            alignItems="flex-start"
            mb={isPrintable ? 0 : [10, 10, 10, 0]}
            ml={0}
            mr={isPrintable ? 15 : [0, 0, 0, 24]}
            p={isPrintable ? 10 : 3}
            width={isPrintable ? 1 / 2 : ["auto", "auto", "auto", 1 / 2]}
            maxHeight={
              isPrintable
                ? "inherit"
                : [
                    "-webkit-fill-available",
                    "-webkit-fill-available",
                    "-webkit-fill-available",
                    116,
                  ]
            }
          >
            <Text
              textAlign={["center", "center", "start", "start"]}
              lineHeight={
                isPrintable
                  ? "var(--fs-h5) !important"
                  : "var(--lh-text-secondary)"
              }
              fontSize={isPrintable && "var(--fs-text-s) !important"}
            >
              {messages[value.description.title]}
              <Text
                lineHeight={
                  isPrintable
                    ? "var(--fs-h5) !important"
                    : "var(--lh-text-secondary)"
                }
                fontSize={isPrintable && "var(--fs-text-s) !important"}
              >
                {messages[value.description.subTitle]}
              </Text>

              <Text
                lineHeight={
                  isPrintable
                    ? "var(--fs-h5) !important"
                    : "var(--lh-text-secondary)"
                }
                fontSize={isPrintable && "var(--fs-text-s) !important"}
              >
                {messages[value.description.description]}
              </Text>
            </Text>
          </Div>

          <Div
            display="flex"
            flexDirection="column"
            justifyContent={["flex-start", "flex-start", "center", "center"]}
            alignItems={["center", "center", "flex-start", "flex-start"]}
            width={isPrintable ? 170 : ["auto", "auto", "auto", 370]}
            height={isPrintable ? "inherit" : ["auto", "auto", "auto", 116]}
            backgroundColor={"var(--turquoise-light)"}
          >
            <Text
              textAlign={["center", "center", "start", "start"]}
              p={isPrintable ? 10 : 3}
              lineHeight={
                isPrintable
                  ? "var(--fs-h5) !important"
                  : "var(--lh-text-secondary)"
              }
              fontSize={isPrintable && "var(--fs-text-s) !important"}
            >
              {messages[value.footer]}
            </Text>
          </Div>
        </Div>
      </Div>
    ));

  return (
    <Div>
      <TextLarge
        lineHeight={isPrintable && "150% !important"}
        fontSize={isPrintable && "var(--fs-text-s) !important"}
        fontWeight="var(--light-weight)"
      >
        {messages[description]}
      </TextLarge>
      <Div mt={3}>{constructGrades(grade)}</Div>
      <TextLarge
        display="block"
        fontSize={isPrintable && "var(--fs-text-s) !important"}
        lineHeight={
          isPrintable ? "150% !important" : "var(--lh-text-secondary)"
        }
        fontWeight="var(--light-weight)"
      >
        {messages[footer]}
      </TextLarge>
    </Div>
  );
};

StatementScale.propTypes = {
  isPrintable: PropTypes.bool,
  config: PropTypes.object.isRequired,
};

export default StatementScale;
