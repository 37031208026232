import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";
import { H2, H4 } from "@components/Heading";
import Span from "@components/Span";
import {
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryButtonOutlined,
} from "@components/Button";
import Icon from "@components/Icon";
import Editor from "@components/Editor";

import { formatDateAndTime } from "@utils/utils";
import editComment from "@app/services/assignments/editComment";
import deleteComment from "@app/services/assignments/deleteComment";

import styled from "styled-components";

const StyledCommentBody = styled.div`
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
  ul {
    margin-top: 10px;
    li {
      margin-left: 20px;
      margin-top: 5px;

      &::before {
        content: "- ";
      }
    }
  }
`;

const CommentsTab = ({ comments, onAddComments, onUpdateComments }) => {
  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);

  const [newComment, setNewComment] = useState("");
  const [isEditComment, setIsEditComment] = useState(false);

  const editedCommentId = useRef(null);

  const handleOnChangeComment = (event) => {
    const { htmlValue } = event;
    setNewComment(htmlValue);
  };

  const handleAddComment = () => {
    const newComments = comments;
    const currentDate = new Date();

    newComments.push({
      comment: newComment,
      created_at: currentDate.toISOString(),
      user: {
        name: userInfo?.name,
        type: userInfo?.type,
      },
    });

    onAddComments(newComments);

    setNewComment("");
  };

  const handleEditComment = (commentKey, commentId) => {
    setIsEditComment(true);
    setNewComment(comments[commentKey].comment);

    editedCommentId.current = commentId;
  };

  const handleCancelEditComment = () => {
    setIsEditComment(false);
    setNewComment("");
  };

  const handleConfirmEditComment = async () => {
    handleCancelEditComment();

    if (editedCommentId.current == null) {
      return;
    }

    const newComments = comments?.map((commentObject) =>
      commentObject.id === editedCommentId.current
        ? {
            ...commentObject,
            comment: newComment,
          }
        : commentObject,
    );

    const editedComment = newComments?.find(
      (commentObject) => commentObject.id === editedCommentId.current,
    );

    onUpdateComments(newComments);

    try {
      await editComment(editedComment);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleDeleteComment = async (commentKey, commentId) => {
    let newComments = comments;
    delete newComments[commentKey];

    onUpdateComments(newComments);

    try {
      await deleteComment(commentId);
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <Div>
      <H2 color="var(--blue-dark) !important">{messages.label_comments}</H2>
      {comments?.map((comment, key) => {
        const { comment: text = "", created_at = "", user = {} } = comment;

        return (
          <Div width={[1, 1, 8 / 10]} key={created_at} my={3}>
            <Div
              display={["block", "flex"]}
              justifyContent="space-between"
              mb={2}
            >
              <Div display="flex" alignItems="center">
                <Icon
                  name="account-circle"
                  mr={1}
                  color="var(--turquoise)"
                  fontSize="var(--fs-h3)"
                />
                <H4 fontWeight="var(--semibold-weight) !important" mr={4}>
                  {user?.name}
                </H4>
                <Span
                  color="var(--light-gray) !important"
                  fontWeight="var(--medium-weight) !important"
                  lineHeight="21px"
                >
                  {user?.type}
                </Span>
              </Div>
              <Div display="flex" alignItems="center" gridGap={2}>
                <Span color="var(--grey) !important" light>
                  {formatDateAndTime(created_at)}
                </Span>
                <Icon
                  name="pen"
                  fontSize="var(--fs-icon)"
                  color="var(--turquoise)"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleEditComment(key, comment.id);
                  }}
                />
                <Icon
                  name="trash"
                  fontSize="var(--fs-icon-m)"
                  color="var(--turquoise)"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDeleteComment(key, comment.id);
                  }}
                />
              </Div>
            </Div>
            <Div>
              <StyledCommentBody dangerouslySetInnerHTML={{ __html: text }} />
            </Div>
          </Div>
        );
      })}
      <Div mt={comments?.length ? 4 : 3}>
        <TextMediumWeight>{messages.label_add_comment}</TextMediumWeight>
        <Editor
          width={[1, 1, 8 / 10]}
          height={"150px"}
          modules={{
            toolbar: [["bold", "italic", "underline"], [{ list: "bullet" }]],
          }}
          my={2}
          value={newComment}
          onTextChange={handleOnChangeComment}
        />
      </Div>
      <Div width={[1, 1, 8 / 10]} display="flex" justifyContent="flex-end">
        <Div mt={60}>
          {isEditComment ? (
            <>
              <PrimaryButtonOutlined
                rounded
                height={40}
                px={22}
                mr={30}
                label={messages.label_cancel}
                onClick={handleCancelEditComment}
              />
              <PrimaryButton
                rounded
                height={40}
                px={22}
                label={messages.text_add}
                onClick={() => {
                  handleConfirmEditComment();
                }}
              />
            </>
          ) : (
            <PrimaryButtonIcon
              rounded
              height={40}
              px={22}
              icon={<Icon name="add-comment" mr={1} />}
              label={messages.label_add_comment}
              onClick={() => {
                handleAddComment();
              }}
            />
          )}
        </Div>
      </Div>
    </Div>
  );
};

CommentsTab.propTypes = {
  comments: PropTypes.array,
  onAddComments: PropTypes.func,
  onUpdateComments: PropTypes.func,
};

export default CommentsTab;
