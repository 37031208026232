import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";

import {
  PrimaryButtonIcon,
  PrimaryButtonIconOutlined,
} from "@components/Button";
import Div from "@components/Div";
import Dropdown from "@components/Dropdown";
import Editor from "@components/Editor";
import InputText from "@components/InputText";
import { TextUpperCase, TextSmall } from "@components/Text";

import {
  INPUT,
  SVLANG,
  SUBSCRIPTION_STATUS,
  STATUS_IN_ACTIVE,
} from "@utils/constant";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(SVLANG.error_product_name),
  price: Yup.number()
    .typeError(SVLANG.error_price_integer)
    .integer(SVLANG.error_price_integer)
    .min(0, SVLANG.error_price_integer)
    .required(SVLANG.error_price_validation),
  description: Yup.string().required(SVLANG.error_product_description),
  discount: Yup.number()
    .typeError(SVLANG.text_invalid_discount)
    .integer(SVLANG.error_type_discount)
    .min(0, SVLANG.error_type_discount)
    .required(SVLANG.error_discount),
  credits: Yup.number()
    .typeError(SVLANG.error_credits_integer)
    .integer(SVLANG.error_credits_integer)
    .min(0, SVLANG.error_credits_integer)
    .required(SVLANG.error_price_credits),
});

const initialData = {
  name: "",
  price: "",
  description: "",
  credits: null,
  state: "active",
  type: "subscription",
};

const Form = ({
  onSubmit = () => {},
  data = initialData,
  isEditSubscription = false,
}) => {
  const { messages } = useIntl();

  const { NUMBER, TEXT } = INPUT.TYPE;
  const { hasAllPermissions } = useAuthorization();
  const hasWriteProductsPermission = hasAllPermissions([AdminPermissions.AdminWriteProducts]);


  const handleOnSubmit = (values) => {
    onSubmit(values);
  };

  const handleChangeIndescription = (onTextChange, e) => {
    onTextChange({ target: { value: e.htmlValue, name: "description" } });
  };

  const editorToolbarOptions = [
    ["bold", "italic", "underline", { list: "bullet" }],
  ];

  const statusValues = [
    {
      label: messages.active,
      value: SUBSCRIPTION_STATUS.ACTIVE,
    },
    {
      label: messages.state_in_active,
      value: STATUS_IN_ACTIVE,
    },
  ];

  return (
    <Div width={1}>
      <Formik
        enableReinitialize
        validationSchema={ValidationSchema}
        initialValues={data}
        onSubmit={handleOnSubmit}
      >
        {(prop) => {
          const {
            values,
            handleReset,
            handleSubmit,
            handleChange,
            dirty,
            isValid,
          } = prop;
          return (
            <FormikForm>
              {isEditSubscription && (
                <Div width={[1, 1, 1, 1 / 2]} mr={[0, 0, 0, 2]} mt={3}>
                  <Div width={1} mb={2}>
                    <TextUpperCase>{messages.label_status}</TextUpperCase>
                  </Div>
                  <Dropdown
                    placeholder={messages.placeholder_choose}
                    value={values?.state}
                    name={"state"}
                    options={statusValues}
                    onChange={handleChange}
                    width={1}
                    mb={0}
                  />
                </Div>
              )}
              <Div
                display="flex"
                width={1}
                flexDirection={["column", "column", "row", "row"]}
              >
                <Div mr={3} width={[1, 1, 1, "40%"]}>
                  <InputText
                    curved
                    placeholder={messages.label_subscription_name}
                    name={"name"}
                    formikProps={prop}
                    type={TEXT}
                    label={messages.label_subscription_name}
                    labelAlignment="left"
                    width={1}
                    value={values?.name}
                  />
                </Div>
                <Div
                  ml={[0, 0, 0, 2]}
                  mr={[0, 0, 0, 2]}
                  width={[1, 1, 1, "30%"]}
                >
                  <Div
                    display="flex"
                    mb={2}
                    mt={3}
                    pt={1}
                    justifyContent="space-between"
                  >
                    <TextUpperCase>
                      {messages.watchlist_label_price}
                    </TextUpperCase>
                    <TextSmall color={" var(--grey-dark) !important"}>
                      {messages.price_currency_label}
                    </TextSmall>
                  </Div>
                  <InputText
                    curved
                    placeholder={messages.watchlist_label_price}
                    name={"price"}
                    formikProps={prop}
                    type={NUMBER}
                    labelAlignment="left"
                    width={1}
                    value={values?.price}
                  />
                </Div>
                <Div ml={[0, 0, 0, 3]} width={[1, 1, 1, "30%"]}>
                  <InputText
                    curved
                    placeholder={messages.label_credits_in_package}
                    name={"credits"}
                    formikProps={prop}
                    type={NUMBER}
                    label={messages.label_credits_in_package}
                    labelAlignment="left"
                    width={1}
                    value={values?.credits}
                  />
                </Div>
              </Div>
              <Div>
                <InputText
                  curved
                  placeholder={messages.label_discount_percentage}
                  name={"discount"}
                  formikProps={prop}
                  type={NUMBER}
                  label={messages.label_discount_percentage}
                  labelAlignment="left"
                  width={[1, 1, 1 / 2, 1 / 2]}
                  value={values?.discount}
                />
              </Div>
              <Div mt={3}>
                <Div width={1} mb={2}>
                  <TextUpperCase>{messages.label_description}</TextUpperCase>
                </Div>
                <Editor
                  theme="snow"
                  onTextChange={handleChangeIndescription.bind(
                    this,
                    handleChange,
                  )}
                  value={values?.description}
                  modules={{ toolbar: editorToolbarOptions }}
                  pt={{
                    content: { style: { height: "250px" } },
                  }}
                />
              </Div>
              <Div mt={32}>
                <PrimaryButtonIcon
                  type="submit"
                  rounded
                  minWidth={186}
                  semibold
                  mr={[0, 0, 0, 30]}
                  label={messages.label_save}
                  mb={[24, 24, 24, 0]}
                  width={[1, 1, 1, "auto"]}
                  height={40}
                  onClick={handleSubmit}
                  disabled={
                    hasWriteProductsPermission ? !(isValid && dirty) : true
                  }
                />
                {hasWriteProductsPermission && dirty && (
                  <PrimaryButtonIconOutlined
                    type="button"
                    label={messages.label_cancel}
                    rounded
                    semibold
                    py={2}
                    px={4}
                    onClick={handleReset}
                    height={40}
                    width={[1, 1, 1, "auto"]}
                    minWidth={206}
                    fontWeight={"var(--semibold-weight)"}
                    fontSize={"var(--fs-h5)"}
                  />
                )}
              </Div>
            </FormikForm>
          );
        }}
      </Formik>
    </Div>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  isEditSubscription: PropTypes.bool,
};
export default Form;
