import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import Breadcrumb from "@components/Breadcrumb";
import Div from "@components/Div";
import {
  ROUTES,
  REPORT,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETED,
  STATUS_EXPIRED,
} from "@utils/constant";
import fetchAssignmentDetails from "@app/services/assignments/fetchAssignmentDetails";
import userService from "@app/services/users/userService";
import updateAssignment from "@app/services/assignments/updateAssignment";

import Tabs from "@components/Tabs";
import SetAssigneeDialog from "./SetAssigneeDialog";
import CompleteAssignmentsDialog from "./CompleteAssignmentsDialog";
import CommentsTab from "./CommentsTab";
import DetailsTab from "./DetailsTab";
import AdminContainer from "@layout/AdminContainer";

const Details = () => {
  const { messages } = useIntl();
  const { search = "" } = useLocation();
  const { id: assignmentId = "" } = queryString.parse(search);
  const [assignment, setAssignment] = useState({});
  const [customers, setCustomers] = useState([]);
  const [order, setOrder] = useState({});
  const [showAssignees, setShowAssignees] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [
    isCompleteAssignmentsDialogVisible,
    setIsCompleteAssignmentsDialogVisible,
  ] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [expiredDate, setExpiredDate] = useState("");

  const items = [
    {
      label: messages.title_assignments,
      url: ROUTES.ADMIN_ASSIGNMENTS_LIST.URL,
    },
    {
      label: `#${assignment?.id}`,
    },
  ];

  const getAssignmentDetails = async () => {
    try {
      const response = await fetchAssignmentDetails(assignmentId);
      const {
        data: { assignment: assignmentData = {}, order: orderData = {} } = {},
      } = response;
      const { user = {}, reports = [] } = assignmentData;
      const documents = reports[0]?.documents;
      assignmentData.documents = documents || [];
      setSelectedAssignee(user);
      setAssignment(assignmentData);
      setOrder(orderData);

      fetchExpiryDate(assignmentData);
    } catch (e) {
      throw new Error(e);
    }
  };

  useEffect(() => {
    getAssignmentDetails();
    try {
      const loadCustomerData = async () => {
        const response = await userService("filter[type]=admin&per_page=100");
        let { data: { data: users = [] } = {} } = response;
        users = users.map((obj) => {
          const { name = "", id = "", firstname = "", lastname = "" } = obj;
          return {
            label: name,
            value: id,
            firstname: firstname,
            lastname: lastname,
          };
        });
        setCustomers(users);
      };
      loadCustomerData();
    } catch (e) {
      throw new Error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReportChange = (event) => {
    let { report = [] } = assignment;
    report = [event];
    setAssignment({ ...assignment, report: report });
  };

  const handleSaveChanges = async () => {
    assignment.comments =
      assignment.comments[assignment.comments?.length - 1]?.comment;

    fetchExpiryDate(assignment);

    try {
      const response = await updateAssignment(assignment);

      const {
        data: { data: resData = {} },
      } = response;
      const { reports = [] } = resData;
      resData.documents = reports[0]?.documents;
      setAssignment(resData);
    } catch (e) {
      throw new Error(e);
    }
  };

  const fetchExpiryDate = (assignment) => {
    if (!assignment.expired_at) {
      return;
    }

    const expiryDateTime = new Date(assignment.expired_at);

    const today = new Date();

    if (today.getTime() >= expiryDateTime.getTime()) {
      assignment.status = STATUS_EXPIRED;

      setExpiredDate(assignment.expired_at);
    }
  };

  const toggleSetAssigneeDialog = () => {
    setShowAssignees(!showAssignees);
  };

  const toggleCompleteAssignmentDialog = () => {
    setIsCompleteAssignmentsDialogVisible(!isCompleteAssignmentsDialogVisible);
  };

  const handleUpdateAssignee = () => {
    assignment.user = selectedAssignee;
    assignment.status = STATUS_IN_PROGRESS;

    setAssignment(assignment);
    toggleSetAssigneeDialog();

    handleSaveChanges();
  };

  const handleAddComments = (newComments) => {
    handleUpdateComments(newComments);

    handleSaveChanges();
  };

  const handleUpdateComments = (newComments) => {
    setAssignment({ ...assignment, comments: newComments });
  };

  const handleSubmitReport = (event) => {
    handleReportChange(event);

    assignment.status = STATUS_COMPLETED;

    handleSaveChanges();
    toggleCompleteAssignmentDialog();
  };

  const { product = {}, comments = [] } = assignment;

  const handleDropdownSelect = (e) => {
    const customer = customers.find((customer) => customer.value == e.value);

    setSelectedAssignee(customer);
  };

  const handleTabChange = (e) => {
    setActiveIndex(e.index);
  };

  const headerConfig = {
    title: product?.name,
  };
  return (
    <AdminContainer config={headerConfig}>
      <Breadcrumb items={items} ml={"-16px"} mt={"-16px"} />
      <Div pr={[0, 6]} display="flex" flexDirection="column">
        <Div width={[1, "75%"]}>
          <Tabs
            isPlainPage={true}
            content={[
              {
                title: messages.label_details,
                content: (
                  <DetailsTab
                    assignment={assignment}
                    order={order}
                    isExpired={!!expiredDate}
                    onToggleAssigneeDialog={toggleSetAssigneeDialog}
                    onToggleCompleteAssignmentDialog={
                      toggleCompleteAssignmentDialog
                    }
                  />
                ),
              },
              {
                title: messages.label_comments,
                content: (
                  <CommentsTab
                    comments={comments}
                    onAddComments={handleAddComments}
                    onUpdateComments={handleUpdateComments}
                  />
                ),
              },
            ]}
            onTabChange={handleTabChange}
            activeIndex={activeIndex}
          />
        </Div>
      </Div>
      {showAssignees && (
        <SetAssigneeDialog
          selectedAssignee={selectedAssignee?.label || selectedAssignee?.name}
          availableAssignees={customers}
          onHide={toggleSetAssigneeDialog}
          onSetAssignee={handleDropdownSelect}
          onSubmitAssignee={handleUpdateAssignee}
        />
      )}
      {isCompleteAssignmentsDialogVisible && (
        <CompleteAssignmentsDialog
          assignmentId={assignmentId}
          category={REPORT}
          onUploadReport={handleSubmitReport}
          onHide={toggleCompleteAssignmentDialog}
        />
      )}
    </AdminContainer>
  );
};
export default Details;
