import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  useHistory as useReactRouterHistory,
  useLocation,
} from "react-router-dom";

import styled from "styled-components";

import personInformationSearchService from "@app/services/search/personInformationSearchService";
import { fetchAllProducts } from "@app/services/services/fetchProductById";

import Container from "@components/Container";
import { default as Div } from "@components/Div";
import Section from "@components/Section";

import { useOrder } from "@contexts/OrderContext";
import { useStepper } from "@contexts/StepperContext";
import { useUser } from "@contexts/AuthProvider";
import { trackPageView } from "@src/analyticsFunctions";

import {
  SSNNUMBER,
  SINGLE,
  MULTIPLE,
  PROTECTED,
  CONSENT_OPTIONAL,
  CONSENT_MANDATORY,
  PRODUCT_TYPE_SERVICE,
  ROUTES,
  SELECTED_PRODUCTS,
} from "@utils/constant";
import { getPinWithFullYear, splitString } from "@utils/utils";

import AvailableServicesForCandidates from "./AvailableServicesForCandidates";
import { SinglePINLoader, MultiPINLoader } from "./Loader";
import SSNSearch from "./SSNSearch";
import INITIAL_VALUES from "./types";

export const BoxedDiv = styled(Div)`
  border: 1px dashed var(--turquoise);
  box-sizing: border-box;
`;

const SearchResults = () => {
  const { messages } = useIntl();
  const location = useLocation();
  const history = useReactRouterHistory();
  const { isAdmin } = useUser();
  const { orderUser } = useOrder();
  const { setActiveStepIndex } = useStepper();

  const { customer: { status: subscriptionStatus, consent } = {} } =
    orderUser || {};

  useEffect(() => {
    if (!orderUser) {
      if (isAdmin) {
        history.push(ROUTES.ADMIN_WIZARD_SELECT_CUSTOMER.URL);
      } else {
        history.push(ROUTES.WIZARD_SEARCH.URL);
      }
    }
  }, [isAdmin, orderUser, history]);

  const [servicesData, setServicesData] = useState([]);
  const [data, setData] = useState({
    ...INITIAL_VALUES,
    consentCheck: consent === CONSENT_OPTIONAL,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [ssnNumber, setSSNNumber] = useState([]);
  const [isError, setIsError] = useState(false);

  const query = new URLSearchParams(location.search);

  const numberSsn = splitString(
    query.get(SSNNUMBER) ? query.get(SSNNUMBER).replaceAll(",", "\n") : ""
  );
  const selectedProducts = query.get(SELECTED_PRODUCTS)?.split(",");

  const _ssnNumber = numberSsn.map(value => {
    const pin = value.replaceAll("-", "");
    if (pin.length === 10) {
      const appendedValue = getPinWithFullYear(pin);
      return appendedValue;
    }
    return pin;
  });

  const resetStateValues = () => {
    setData({});
    setSSNNumber([]);
  };

  const sanitizeData = (servicesData, responseData, _ssnNumber) => {
    const isCases = servicesData.some(({ is_case }) => is_case);
    const defaultServiceData = servicesData
      .filter(({ is_case }) => (isCases ? is_case : true))
      .map((service = {}) => {
        const serviceId = String(service?.id);
        const defaultSelected = selectedProducts
          ? selectedProducts.includes(serviceId)
          : service.default_selected > 0;

        return {
          ...service,
          isChecked: defaultSelected,
        };
      });

    _ssnNumber.forEach(ssn => {
      const pin = ssn && ssn.replaceAll("-", "");
      if (
        !responseData.some(resData => {
          return resData.ssn === pin && resData.ssn_status !== PROTECTED;
        })
      ) {
        return responseData.push({
          ssn: ssn,
          error: true,
        });
      }
    });
    return {
      ...data,
      candidates: responseData
        .filter(i => i.ssn_status !== PROTECTED)
        .map(ssnDetails => ({
          ...ssnDetails,
          email: "",
          phone: "",
          services: defaultServiceData,
          showAllServices: false,
        })),
    };
  };

  const getPersonInformation = async (_ssnNumber = []) => {
    try {
      resetStateValues();
      setIsError(false);
      setIsFetching(true);

      const servicesResponse = await fetchAllProducts({
        type: PRODUCT_TYPE_SERVICE,
        customer_id: orderUser?.customer?.id,
      });
      const { data = {} } = servicesResponse;
      const { data: serviceData = [] } = data;
      const availableServices = serviceData.filter(
        ({ default_available }) => default_available === 1
      );
      setServicesData(availableServices);
      const response = await personInformationSearchService(_ssnNumber);
      const { data: responseData = [] } = response;

      if (responseData.length === 0) {
        const errorResponse = {
          candidates: [
            {
              ssn: _ssnNumber,
              error: true,
              isChecked: false,
            },
          ],
        };
        setData(errorResponse);
        setIsError(true);
      } else if (responseData.length !== _ssnNumber.length) {
        setData(sanitizeData(availableServices, responseData, _ssnNumber));
      } else {
        setData(sanitizeData(availableServices, responseData, _ssnNumber));
      }
      setSSNNumber(_ssnNumber);
      setIsFetching(false);
    } catch (e) {
      const errorResponse = [
        {
          ssn: _ssnNumber,
          error: true,
          isChecked: false,
        },
      ];
      setData(errorResponse);
      setIsError(true);
      setSSNNumber(_ssnNumber);
      setIsFetching(false);
    }
  };

  const optionalInfoLabel = () => {
    if (consent === CONSENT_MANDATORY) {
      return messages.consent_mandatory_info;
    }
    return messages.consent_optional_text;
  };

  useEffect(() => {
    trackPageView();
    if (_ssnNumber.length) {
      getPersonInformation(_ssnNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.candidates?.length) {
      setActiveStepIndex(isAdmin ? 2 : 1);
    }
  }, [data?.candidates?.length, isAdmin, setActiveStepIndex]);

  const onSearchSSN = (pin = []) => {
    const param = pin.join(",");

    history.replace({
      pathname: location.pathname,
      search: `?ssnnumber=${param}`,
    });

    resetStateValues();

    return getPersonInformation(pin);
  };

  const getLoader = () => (ssnNumber.length > 1 ? MULTIPLE : SINGLE);

  const layoutType = getLoader();

  const renderLoader = () =>
    layoutType === SINGLE ? (
      <SinglePINLoader count={[ssnNumber]} />
    ) : (
      <MultiPINLoader count={ssnNumber} />
    );

  const renderForm = () => (
    <>
      <SSNSearch
        ssnNumbers={ssnNumber}
        onSearchSSN={onSearchSSN}
        onChange={setSSNNumber}
      />

      {!!data.candidates.length && (
        <AvailableServicesForCandidates
          data={data}
          setData={setData}
          ssnNumber={ssnNumber}
          isError={isError}
          consentStatus={consent}
          setIsFetching={setIsFetching}
          servicesData={servicesData}
          setSSNNumber={setSSNNumber}
          subscriptionStatus={subscriptionStatus}
          optionalInfoLabel={optionalInfoLabel}
        />
      )}
    </>
  );

  return (
    <Section px={0} py={0}>
      <Container>
        <Div width={1}>{isFetching ? renderLoader() : renderForm()}</Div>
      </Container>
    </Section>
  );
};

export default SearchResults;
