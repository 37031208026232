import React, { memo, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import styled from "styled-components";

import { default as Accordion } from "@components/Accordion";
import Container from "@components/Container";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import Section from "@components/Section";

import useAnalysisReport from "@utils/useAnalysisReport";

import Heading from "./Heading";
import Method from "./Method";
import PersonDetails from "./PersonDetails";
import Recommendatations from "./Recommendation";
import RiskStatement from "./RiskStatement";
import StatementScale from "./StatementScale";

import { PDFLoader } from "../components";

const StyledAccordion = styled(Accordion)`
  .p-accordion-tab.p-accordion-tab-active {
    margin-bottom: 0px !important;
  }

  .p-accordion-content {
    padding-top: 0px;
  }
`;

const LegacyReport = ({ report, reloadDetails, isLoading }) => {
  const { messages } = useIntl();

  const [isPDFGenerating, setIsPDFGenerating] = useState(false);

  const {
    id: reportId = "",
    product: _product = {},
    analysis_type: analysisType = "",
  } = report;
  const { grade_mapping: gradeMapping = {} } = _product;

  const Deviations = useAnalysisReport({ type: analysisType });

  const data = useMemo(
    () => [
      {
        title: (
          <H2 color="var(--blue-dark) !important">
            {messages.title_recommendations}
          </H2>
        ),
        content: <Recommendatations />,
      },
      {
        title: (
          <H2 color="var(--blue-dark) !important">{messages.title_method}</H2>
        ),
        content: <Method />,
      },
      {
        title: (
          <H2 color="var(--blue-dark) !important">
            {messages.title_statement_scale}
          </H2>
        ),
        content: <StatementScale config={gradeMapping} />,
      },
    ],
    [messages, gradeMapping]
  );

  const handlePDFGenerateStart = () => {
    setIsPDFGenerating(true);
  };

  const handlePDFGenerateFinish = () => {
    setTimeout(setIsPDFGenerating, 2000, false);
  };

  return (
    <>
      {isPDFGenerating && <PDFLoader />}

      <Div
        bg="var(--white)"
        minHeight="100vh"
        px={[3, 3, 4, 4]}
        mx={[-16, -16, 0, 0]}
        className={isPDFGenerating ? "report-pdf" : ""}
      >
        {!isLoading && (
          <Heading
            reportId={reportId}
            onPDFGenerationStart={handlePDFGenerateStart}
            onPDFGenerationFinish={handlePDFGenerateFinish}
          />
        )}

        <Section
          mx={["auto", "auto", "auto", "0%"]}
          px="0px !important"
          py="16px !important"
        >
          <Container>
            {!isLoading && (
              <Div>
                <PersonDetails
                  report={report}
                  config={gradeMapping?.personalDetails}
                />
                <RiskStatement report={report} config={gradeMapping} />
                <Deviations report={report} reloadDetails={reloadDetails} />
              </Div>
            )}
          </Container>
        </Section>

        {!isLoading && (
          <Section
            mx={["auto", "auto", "auto", "0%"]}
            px={"0px !important"}
            pt={"0px !important"}
          >
            <Container>
            <StyledAccordion content={data} alignArrowLeft />
            </Container>
          </Section>
        )}
      </Div>
    </>
  );
};

LegacyReport.propTypes = {
  report: PropTypes.object,
  reloadDetails: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default memo(LegacyReport);
