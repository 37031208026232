import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";
import { formatDateAndTime } from "@utils/utils";
import ActivityLogDetails from "../../activityLogs/Details";

export const ActivityLogsTab = ({ id }) => {
  const { messages } = useIntl();
  const [selectedActivity, setSelectedActivity] = useState("");
  const [showActivityLog, setShowActivityLog] = useState(false);

  const handleActivityLogDetails = (values = "") => {
    setSelectedActivity(showActivityLog ? "" : values);
    setShowActivityLog(!showActivityLog);
  };

  const dataTableConfig = {
    enable_filter: false,
    backend_querying: true,
    no_records_message: "datatable_admin_customer_users_no_objects_found",
    enable_search: false,
    enable_query_builder: true,
    enable_csv_download: false,
    api: {
      resource: `/customers/${id}/activity-log`,
      method: "GET",
      params: [
        [
          "include",
          [
            "id",
            "user",
            "new_values",
            "type",
            "created_at",
            "model",
            "old_values",
          ],
        ],
      ],
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
      },
      {
        title: "label_purchaser",
        db_field: "user.name",
        type: "text",
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
        formatter: value => (value ? formatDateAndTime(value) : "-"),
      },
      {
        title: "type_label",
        db_field: "type",
        type: "enum",
      },
      {
        title: "model",
        db_field: "model",
        type: "text",
        formatter: value => {
          const model = value?.split("App\\Models\\")?.[1];
          return model ? messages[model.toLowerCase()] : "-";
        },
      },
      {
        type: "actions",
        width: "50px",
        icon: "menu-report",
        actions: [
          {
            icon: "menu-report",
            type: "button",
            onClick: handleActivityLogDetails,
          },
        ],
      },
    ],
  };

  return (
    <>
      <Container m={"0px !important"}>
        <DataTable config={dataTableConfig} />
      </Container>
      {showActivityLog && (
        <ActivityLogDetails
          data={selectedActivity}
          handleClose={handleActivityLogDetails}
        />
      )}
    </>
  );
};
ActivityLogsTab.propTypes = {
  id: PropTypes.string,
};
export default ActivityLogsTab;
