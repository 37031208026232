import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { saveAs } from "file-saver";
import Papa from "papaparse";
import userService from "@app/services/users/userService";

import customerService from "@app/services/customers/customerService";
import fetchOrdersForInvoiceReport from "@app/services/orders/fetchOrdersForInvoiceReport";
import fetchProductById from "@app/services/services/fetchProductById";

import DataTable from "@components/DataTableV2/DataTable";
import ErrorDialog from "@components/ErrorDialog";

import AdminContainer from "@layout/AdminContainer";
import ProgressSpinner from "@components/ProgressSpinner";

import { ROUTES, ORDERS_API, ADMIN_CUSTOMER_DETAILS } from "@utils/constant";
import { flattenOrderObject } from "@utils/utils";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";

const searchableColumns = [
  "id",
  "number",
  "customer.customer_name",
  "user.firstname",
  "user.lastname",
];

const FILTER_KEY_FROM = "filter[created_at][gte]";
const FILTER_KEY_TO = "filter[created_at][lte]";

const FILTER_KEY_PAYMENT_ID = "filter[payments.payment_method_id]";

const List = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const { hasAllPermissions, hasAnyPermissions } = useAuthorization();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);

  const hasCustomerPermission = hasAnyPermissions([
    AdminPermissions.AdminWriteCustomers,
    AdminPermissions.AdminReadCustomers,
  ]);
  const hasOrdersWritePermission = hasAllPermissions([
    AdminPermissions.AdminWriteOrders,
  ]);
  const hasReadBillingPermission = hasAllPermissions([
    AdminPermissions.AdminReadBilling,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [customersResponse, servicesResponse, userServices] =
          await Promise.all([
            customerService(),
            fetchProductById(),
            userService(),
          ]);

        const { data: { data: customers = [] } = {} } = customersResponse;
        const formattedCustomers = customers.map(o => ({
          name: `${o?.customer_name} #${o?.id}`,
          code: o?.id,
        }));

        const { data: { data: products = [] } = {} } = servicesResponse;
        const formattedServices = products.map(o => ({
          name: `${o?.name} #${o?.id}`,
          code: o?.id,
        }));

        const { data: { data: userData = [] } = {} } = userServices;
        const formattedUsers = userData.map((o) => ({
          name: `${o?.name} #${o?.id}`,
          code: o?.id,
        }));
        setUsers(formattedUsers);
        setCustomers(formattedCustomers);
        setServices(formattedServices);
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, []);

  const handleOpenWizard = () => {
    history.push(ROUTES.ADMIN_WIZARD_SELECT_CUSTOMER.URL);
  };

  const getUserNames = orderItems => {
    const personNames = orderItems.map(i => {
      return i.person_initials;
    });
    const uniqueUsers = [...new Set(personNames)];

    if (uniqueUsers[0] === "") return "-";

    const usersLength = uniqueUsers.length;

    if (!usersLength) return "-";

    if (usersLength === 1) return uniqueUsers[0];
    else return `${uniqueUsers[0]} + ${usersLength - 1}`;
  };

  const fetchInvoiceOrders = async () => {
    try {
      return await fetchOrdersForInvoiceReport("?export=1").then(response => {
        const { data: invoiceData = {} } = response;

        return invoiceData;
      });
    } catch (e) {
      return e.response;
    }
  };

  const handleDownloadInvoice = async () => {
    try {
      setDownloadLoading(true);

      const csvData = await fetchInvoiceOrders();
      const { data = [] } = csvData || {};
      const maxOrderItems = Math.max(
        ...data.map(order => order.order_items.length)
      );
      const flatData = data.map(item =>
        flattenOrderObject(item, maxOrderItems)
      );
      const csv = Papa.unparse(flatData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      saveAs(blob, `${ORDERS_API}.csv`);
    } catch (e) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setDownloadLoading(false);
    }
  };

  const config = {
    header: {
      title: "label_order_page",
      actions: [
        {
          id: "new-order",
          type: "button",
          label: messages.label_new_order,
          onClick: handleOpenWizard,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasOrdersWritePermission,
          mr: 3,
        },
        {
          id: "invoice-report",
          type: "button",
          label: messages.label_invoice_report,
          width: "auto",
          icon: "download-file",
          variant: "header",
          onClick: handleDownloadInvoice,
          isHidden: !hasReadBillingPermission,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.order_filter_description,
        },
      ],
    },
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        filter_by_startdate: "filter[created_at][eq]",
        label: messages.label_date,
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.label_payment_method,
        id: FILTER_KEY_PAYMENT_ID,
        type: "checkbox",
        options: [
          {
            label: messages.klarna,
            value: 1,
            checked: false,
          },
          {
            label: messages.invoice,
            value: 2,
            checked: false,
          },
          {
            label: messages.watchlist_title_credits,
            value: 4,
            checked: false,
          },
          {
            label: messages.credits_and_invoice,
            value: 5,
            checked: false,
          },
        ],
      },
      {
        title: messages.title_customers,
        id: "filter[customer_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[customer_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[customer_id]",
            options: customers,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.title_users,
        id: "filter[user_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[user_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[user_id]",
            options: users,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_services,
        id: "filter[orderItems.product_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[orderItems.product_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[orderItems.product_id]",
            options: services,
            type: "multiselect",
          },
        ],
      },
    ],
    enable_csv_download: true,
    csv_filename: `orders.csv`,
    backend_querying: true,
    no_records_message: "datatable_admin_orders_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${ORDERS_API}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          ["id", "created_at", "total_price", "customer_id", "user_id"],
        ],
        ["include", ["customer", "user", "orderItems", "payments"]],
        ["filter[type]", "PO"],
      ],
    },
    columns: [
      {
        title: "watchlist_title_order_id",
        db_field: "id",
        type: "id",
        className: "text-blue",
        width: "100px",
        href: values => {
          const { id } = values;
          return `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${id}`;
        },
        sortable: true,
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
        width: "13%",
      },
      {
        title: "title_customer",
        db_field: "customer.customer_name",
        type: hasCustomerPermission ? "link" : "text",
        href: values =>
          `${ROUTES.ADMIN_CUSTOMERS.URL}/${values["customer_id"]}/${ADMIN_CUSTOMER_DETAILS}`,
      },
      {
        title: "label_purchaser",
        db_field: "user.name",
        type: "text",
        formatter: value => value || messages.label_deleted_user,
      },
      {
        title: "datasource_candidate",
        db_field: "order_items",
        type: "text",
        formatter: value => {
          return getUserNames(value);
        },
        sortable: true,
        sortField: "orderItems.person_initials",
        width: "100px",
      },
      {
        title: "label_service",
        db_field: "order_items",
        type: "text",
        formatter: value => {
          const totalOrderItems = value?.length;
          return totalOrderItems > 1
            ? `${value[0]?.name} + ${totalOrderItems - 1}`
            : value[0]?.name;
        },
        width: "25%",
      },
      {
        title: "watchlist_label_price",
        db_field: "total_price",
        type: "currency",
        width: "100px",
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: values => `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${values.id}`,
          },
        ],
      },
    ],
  };

  let headerConfig = [
    {
      title: messages.label_order_page,
    },
  ];

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  return (
    <AdminContainer config={headerConfig} wide>
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      {customers.length > 0 && services.length > 0 && (
        <DataTable config={config} />
      )}
      {downloadLoading && <ProgressSpinner/>}
    </AdminContainer>
  );
};

export default List;
