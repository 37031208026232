export const QUESTION_DIALOG_TYPE = {
  CREATE: "create",
  DELETE: "delete",
  NONE: "none",
};

export const QUESTION_DIALOG_CONTENT = {
  [QUESTION_DIALOG_TYPE.CREATE]: {
    titleKey: "header_generate_new_api_keys",
    messageKey: "label_about_to_create_new_api_keys",
    confirmLabelKey: "label_create_keys",
    handleConfirm: () => {},
  },
  [QUESTION_DIALOG_TYPE.DELETE]: {
    titleKey: "header_delete_api_keys",
    messageKey: "label_about_to_delete_selected_api_keys",
    confirmLabelKey: "label_delete_keys",
    handleConfirm: () => {},
  },
  [QUESTION_DIALOG_TYPE.NONE]: null,
};
