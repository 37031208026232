import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import editCustomerInfoService from "@app/services/users/editCustomerInfoService";

import Div from "@components/Div";
import ErrorDialog from "@components/ErrorDialog";
import DataTable from "@components/DataTableV2/DataTable";
import ProgressSpinner from "@components/ProgressSpinner";
import Switch from "@components/Switches";
import { TextLargeSemiBoldWeight } from "@components/Text";

import { INTEGRATIONS_API_URL } from "@utils/constant";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";

const ENDPOINTS = [
  {
    name: "person-information",
    code: "/integrations/v1/person-information",
  },
  {
    name: "products",
    code: "/integrations/v1/products",
  },
  {
    name: "orders",
    code: "/integrations/v1/orders",
  },
  {
    name: "reports",
    code: "/integrations/v1/reports",
  },
  {
    name: "reports",
    code: "/integrations/v1/reports/{id}",
  },
];

const STATUSES = [
  {
    name: "200",
    code: "200",
  },
  {
    name: "500",
    code: "500",
  },
  {
    name: "403",
    code: "403",
  },
  {
    name: "404",
    code: "404",
  },
];

const IntegrationsAPILogs = ({ customerData, updateCustomerDetails }) => {
  const { messages } = useIntl();

  const { integration_api_enabled: customersApiEnabled } = customerData || {};

  const [isIntegrationsApiEnabled, setIsIntegrationsApiEnabled] = useState(
    !!customersApiEnabled,
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { hasAllPermissions } = useAuthorization();

  const hasWriteAccessToCustomers = hasAllPermissions([
    AdminPermissions.AdminWriteCustomers,
  ]);

  const apiStateLabel = customersApiEnabled
    ? messages.active.toLowerCase()
    : messages.label_disabled.toLowerCase();

  const handleCustomersApiActivationChange = async (event) => {
    try {
      setIsLoading(true);

      const { value } = event;

      const { data: updatedCustomerData = {} } = await editCustomerInfoService({
        id: customerData.id,
        integration_api_enabled: Number(value),
      });

      updateCustomerDetails({
        ...updatedCustomerData,
        integration_api_enabled: value,
      });

      setIsIntegrationsApiEnabled(!!value);
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseErrorModal = () => {
    setErrorMessage("");
  };

  const config = {
    header: {
      title: "",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    is_filter_options_updated: true,
    filters: [
      {
        title: messages.header_endpoint,
        id: "filter[endpoint]",
        type: "multiselect",
        options: [
          {
            id: "filter[endpoint]",
            name: "filter[endpoint]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: ENDPOINTS,
          },
        ],
      },
      {
        title: messages.label_date,
        id: "filter[timestamp]",
        type: "calendar",
        label: messages.label_date,
        options: [
          {
            id: ["filter[timestamp][gte]", "filter[timestamp][lte]"],
            label: messages.filter_date_from,
            value: "",
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "multiselect",
        options: [
          {
            id: "filter[status]",
            name: "filter[status]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: STATUSES,
          },
        ],
      },
    ],
    enable_csv_download: true,
    csv_filename: "integrations_logs.scv",
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_query_builder: true,
    api: {
      resource: `${INTEGRATIONS_API_URL}/logs`,
      method: "GET",
    },
    default_sort: {
      field: "timestamp",
      order: "desc",
    },
    columns: [
      {
        db_field: "endpoint",
        title: "header_endpoint",
        type: "text",
      },
      {
        db_field: "method",
        title: "header_method",
        type: "text",
      },
      {
        db_field: "timestamp",
        title: "label_date_and_time",
        type: "dateTime",
      },
      {
        db_field: "status",
        title: "label_status",
        type: "text",
      },
      {
        db_field: "ip",
        title: "header_ip_address",
        type: "text",
      },
    ],
  };

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorModal}
          onConfirm={handleCloseErrorModal}
        />
      )}

      <Div display="flex" alignItems="center" my={3}>
        <Switch
          mr={3}
          checked={isIntegrationsApiEnabled}
          onChange={handleCustomersApiActivationChange}
          disabled={!hasWriteAccessToCustomers}
        />
        <TextLargeSemiBoldWeight>
          {`${messages.label_api_is} ${apiStateLabel}`}
        </TextLargeSemiBoldWeight>
      </Div>

      {isIntegrationsApiEnabled && <DataTable config={config} />}
    </Div>
  );
};

IntegrationsAPILogs.propTypes = {
  customerData: PropTypes.object,
  updateCustomerDetails: PropTypes.func,
};

export default IntegrationsAPILogs;
