import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import getCaseDetailsById from "@app/services/cases/getCaseDetailsById";
import updateCaseDetailsById from "@app/services/cases/updateCaseDetailsById";

import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import {
  CASES_DATA_STATUS_TYPE,
  CASES_STATUS_TYPE,
  FORM_INPUT_TYPE,
  PROCESS_TYPE,
} from "@utils/enum";
import { formatDataStatus, formatDateAndTime } from "@utils/utils";

import AssigneeCard from "./components/AssigneeCard";

const getConfig = (data) => {
  const configuration = {
    generalData: [
      {
        key: "id",
        translationKey: "title_id",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.id,
      },
      {
        key: "name",
        translationKey: "label_service_name",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.product?.name,
        isReadOnlyField: true,
      },
      {
        key: "process_type",
        translationKey: "label_process_type",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="label_automatic" />,
            value: "automatic",
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: "manual",
          },
        ],
        selectorField: "label",
        value: data?.product?.process_type,
      },
      {
        key: "report_status",
        translationKey: "label_report",
        type: FORM_INPUT_TYPE.TEXT,
        value: data?.report_status,
        isReadOnlyField: true,
      },
      {
        key: "created_at",
        translationKey: "created_at",
        type: FORM_INPUT_TYPE.CALENDAR,
        value: formatDateAndTime(data?.created_at),
        isReadOnlyField: true,
      },
      {
        type: FORM_INPUT_TYPE.FILLER,
      },
      {
        key: "data_source",
        translationKey: "label_data_source",
        type: FORM_INPUT_TYPE.MULTISELECT,
        options: data?.product?.data_source,
        selectorField: "name",
        selectAllLabel: <FormattedMessage id="label_all_sources" />,
        value: data?.product?.data_source,
        isReadOnlyField: true,
        fullWidth: true,
      },
    ],
    statuses: [
      {
        key: "status",
        translationKey: "label_case_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id={CASES_STATUS_TYPE.IN_PROGRESS} />,
            value: CASES_STATUS_TYPE.IN_PROGRESS,
          },
          {
            label: <FormattedMessage id={CASES_STATUS_TYPE.COMPLETED} />,
            value: CASES_STATUS_TYPE.COMPLETED,
          },
          {
            label: <FormattedMessage id={CASES_STATUS_TYPE.CANCELLED} />,
            value: CASES_STATUS_TYPE.CANCELLED,
          },
          {
            label: <FormattedMessage id="label_pending" />,
            value: CASES_STATUS_TYPE.PENDING,
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: CASES_STATUS_TYPE.MANUAL,
          },
        ],
        selectorField: "label",
        value: data?.status,
      },
      {
        key: "data_status",
        translationKey: "label_data_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id={"label_pending"} />,
            value: CASES_DATA_STATUS_TYPE.PENDING,
          },
          {
            label: <FormattedMessage id={CASES_DATA_STATUS_TYPE.COMPLETED} />,
            value: CASES_DATA_STATUS_TYPE.COMPLETED,
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: CASES_DATA_STATUS_TYPE.MANUAL,
          },
        ],
        selectorField: "label",
        value: data?.data_status,
        isReadOnlyField: true,
      },
    ],
  };

  return configuration;
};

const DetailsTab = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { case_id } = useParams();
  const { showErrorToast } = useToast();

  const [caseDetails, setCaseDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const hasWriteCasesPermission = hasAllPermissions([
    AdminPermissions.AdminWriteCases,
  ]);

  const handleOpenErrorDialog = useCallback(() => {
    showErrorToast(messages.exception_error_message);
  }, [messages, showErrorToast]);

  const formatReport = useCallback(
    (data) => {
      const { report_id, is_report } = data;
      const reportString = report_id
        ? messages.report_status_completed
        : messages.label_pending;

      return is_report ? reportString : "-";
    },
    [messages],
  );

  const formatData = useCallback(
    (data) => {
      const {
        id,
        created_at,
        status,
        source_data,
        user,
        product = {},
      } = data || {};

      const formattedProduct = {
        name: product.name,
        process_type: product.process_type,
        is_product: !!product.is_product,
        data_source: product.data_source?.map((dataSourceValue) => ({
          name: messages[`datasource_${dataSourceValue}`],
        })),
      };

      const formattedData = {
        id,
        created_at,
        status: status?.replace("-", "_"),
        data_status: formatDataStatus(source_data),
        user,
        product: formattedProduct,
        report_status: formatReport(data),
      };

      return formattedData;
    },
    [messages, formatReport],
  );

  useEffect(() => {
    const fetchCaseDetails = async () => {
      try {
        setIsLoading(true);

        const { data: caseDetailsData } = await getCaseDetailsById(case_id);

        setCaseDetails(caseDetailsData);
      } catch (error) {
        handleOpenErrorDialog();
      } finally {
        setIsLoading(false);
      }
    };

    fetchCaseDetails();
  }, [case_id, messages, handleOpenErrorDialog]);

  const handleUpdateCase = async (values) => {
    try {
      setIsLoading(true);

      const payload = { ...caseDetails, ...values };
      const { data: updatedCaseDetailsData } = await updateCaseDetailsById(
        case_id,
        payload,
      );

      setCaseDetails(updatedCaseDetailsData);
    } catch (error) {
      handleOpenErrorDialog();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateAssignee = (event) => {
    const { value } = event;
    const payload = {
      user: value,
      user_id: value.id,
    };

    handleUpdateCase(payload);
  };

  const config = useMemo(
    () => getConfig(formatData(caseDetails)),
    [formatData, caseDetails],
  );

  const shouldShowAssigneeCard = useMemo(() => {
    const { product: { process_type = "" } = {} } = caseDetails || {};

    return process_type === PROCESS_TYPE.MANUAL && hasWriteCasesPermission;
  }, [caseDetails, hasWriteCasesPermission]);

  return (
    <Div>
      {isLoading && <ProgressSpinner />}

      <EditableFieldsCard
        title={messages.title_general_data}
        config={config.generalData}
      />

      {shouldShowAssigneeCard && (
        <AssigneeCard
          selectedAssignee={caseDetails.user}
          onUpdateAssignee={handleUpdateAssignee}
          onError={handleOpenErrorDialog}
        />
      )}

      <EditableFieldsCard
        title={messages.title_statuses}
        config={config.statuses}
        onSubmit={handleUpdateCase}
        isEditPermission={shouldShowAssigneeCard}
      />
    </Div>
  );
};

export default DetailsTab;
