import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Div from "@components/Div";
import Footer from "@components/Footer";
import {
  HEADER_NAV,
  MYACCOUNTS_NAV,
  INDIVIDUAL,
  COMPANY,
  CANDIDATE,
} from "@utils/constant";
import ScrollToTop from "@utils/ScrollToTop";
import { isAuthenticated } from "@utils/utils";
import { ROUTES } from "../utils/constant";
import CandidateHeader from "@pages/candidate/CandidateHeader";
import useAuthorization from "@hooks/useAuthorization";
import UserHeader from "./UserHeader";

export const StyledDiv = styled(Div)`
  display: flex;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
`;

export const GuestLayout = ({ children }) => {
  const { userInfo: { customer = {}} = {} } = useSelector(
    (state) => state.authReducer,
  );
  const isAuthenticatedFlag = isAuthenticated();
  const { pathname = "" } = useLocation();
  const isReportPdf = pathname === ROUTES.REPORT_PDF.URL;
  const { hasAnyPermissions } = useAuthorization();
  const isCandidatePage = pathname.includes(CANDIDATE);

  const { type: customerType = INDIVIDUAL, prepaid_account: isPrepaidUser } =
    customer;

  const myAccountsNav = MYACCOUNTS_NAV.filter((i) => {
    if (customerType === INDIVIDUAL) {
      if (isPrepaidUser === 1) {
        return i.value !== "company-information" && i.value !== "users";
      } else {
        return !(
          i.value === "subscription" ||
          i.value === "company-information" ||
          i.value === "users"
        );
      }
    }
    if (customerType === COMPANY && isPrepaidUser === 0) {
      return i.value !== "subscription";
    }
    return i;
  });

  let menuItems = myAccountsNav.filter(
    (obj) => hasAnyPermissions(obj?.permissions) || obj?.showByDefault,
  );

  const props = {
    navItems: HEADER_NAV,
    sideMenuNavItems: isAuthenticatedFlag ? menuItems : [],
  };
  return (
    <Div
      display="flex"
      alignItems="center"
      flexDirection="column"
      alignContent="space-between"
      justifyContent="space-between"
      minHeight={"100vh"}
    >
      {!isCandidatePage && !isReportPdf && <UserHeader {...props} />}
      {isCandidatePage && <CandidateHeader />}
      <ScrollToTop>{children}</ScrollToTop>
      {!isReportPdf && <Footer />}
    </Div>
  );
};

GuestLayout.propTypes = {
  children: PropTypes.element,
};

export default GuestLayout;
