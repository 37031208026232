import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { useOrder } from "@contexts/OrderContext";
import { useStepper } from "@contexts/StepperContext";
import { useUser } from "@contexts/AuthProvider";
import Stepper from "@pages/shared/Wizard/Stepper";
import { CloseButton } from "@components/Button";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import Icon from "@components/Icon";
import Steps from "@components/Steps";
import FullSizeDialog from "@components/FullSizeDialog";

import { ROUTES } from "@utils/constant";

import { removeQueryParamValueFromURL } from "./utils";

const NOT_FOUND_INDEX = -1;

const OrderStepper = ({ steps }) => {
  const history = useHistory();
  const { activeStepIndex, setActiveStepIndex } = useStepper();
  const { isLoading, setOrderUser } = useOrder();
  const { isAdmin } = useUser();
  const { pathname, search } = useLocation();
  const currentItem = steps[activeStepIndex];

  useEffect(() => {
    const targetUrl = `${pathname}${search}`;

    const selectedStepIndex = steps.findIndex(({ url = "" }) =>
      url.includes(removeQueryParamValueFromURL(targetUrl))
    );

    if (selectedStepIndex === NOT_FOUND_INDEX) {
      return;
    }

    setActiveStepIndex(selectedStepIndex);
  }, [isAdmin, pathname, search, setActiveStepIndex, steps]);

  const handleClose = () => {

    setOrderUser(undefined);

    if (isAdmin) {
      history.push(ROUTES.ADMIN_ORDERS_LIST.URL);
    } else {
      history.push(ROUTES.SEARCH.URL);
    }
  };

  const renderDialogHeader = (
    <Div flex={1} display="flex" alignItems="center">
      <Div flex={2} display="flex" alignItems="center">
        <H2 color="var(--blue-dark) !important" mr={5}>
          {currentItem?.label}
        </H2>

        <Div flex={1} display={["none", "none", "block", "block"]}>
          <Steps model={steps} activeIndex={activeStepIndex} />
        </Div>
      </Div>

      <Div ml={5} display="flex" justifyContent="flex-end">
        <CloseButton
          rounded
          text
          icon={
            <Icon
              p={2}
              name="close"
              color="var(--grey)"
              fontSize="var(--fs-icon-m)"
            />
          }
          borderRadius="5px !important"
          backgroundColor="var(--grey-lightest) !important"
          onClick={handleClose}
        />
      </Div>
    </Div>
  );

  return (
    <FullSizeDialog header={renderDialogHeader}>
      {!isLoading && <Stepper steps={steps} />}
    </FullSizeDialog>
  );
};

OrderStepper.propTypes = {
  steps: PropTypes.array,
};

export default OrderStepper;
