import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButtonIcon } from "@components/Button";
import Div from "@components/Div";
import Icon from "@components/Icon";
import InputTextArea from "@components/InputTextArea";
import Link, { LinkArrow } from "@components/Link";
import RoundedInputText from "@components/RoundedInputText";
import {
  ColouredSemiBoldText,
  Text,
  ColouredMediumWeight,
} from "@components/Text";

import { trackEventFocus } from "@src/analyticsFunctions";
import Config from "@src/config";

import { ROUTES } from "@utils/constant";
import { ValidateSwedishSSN, getPinWithFullYear } from "@utils/utils";
import { useUser } from "@contexts/AuthProvider";

const SSNSearch = props => {
  const { messages } = useIntl();
  const { isAuthenticated } = useUser();
  const { ssnNumbers = [""] } = props;
  const [isMultiple, setIsMultiple] = useState(ssnNumbers.length > 1);
  const [showMultipleSsnError, setShowMultipleSsnError] = useState([]);

  const handleOnSSNNumberChange = e => {
    setShowMultipleSsnError([]);
    props.onChange(e.target.value.split("\n"));
  };

  const handleMultipleSsnSearch = () => {
    setIsMultiple(true);
  };

  if (ssnNumbers && ssnNumbers.length >= 1) {
    trackEventFocus();
  }

  const handleOnSubmit = () => {
    if (ssnNumbers.length === 0) {
      return;
    }
    const formattedSSNNumbers = ssnNumbers.map(ssn =>
      ssn.length < 12 ? getPinWithFullYear(ssn) : ssn
    );
    const invalidObjects = formattedSSNNumbers.filter(
      obj => !ValidateSwedishSSN(obj)
    );
    const validObjects = formattedSSNNumbers.filter(obj =>
      ValidateSwedishSSN(obj)
    );
    setShowMultipleSsnError(invalidObjects);
    if (invalidObjects.length === 0) {
      return props.onSearchSSN(validObjects);
    }
  };

  return (
    <Div display="flex" flexDirection="column" gridGap="24px">
      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        gridGap="24px"
        height={!isMultiple ? ["auto", "auto", "40px", "40px"] : "auto"}
      >
        {!isMultiple && (
          <Div
            position="relative"
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            gridGap={["24px", "24px", 0, 0]}
            width={[1, 1, "543px", "543px"]}
          >
            <RoundedInputText
              width={1}
              placeholder={messages.placeholder_search}
              value={ssnNumbers.join("\n")}
              onChange={handleOnSSNNumberChange}
            />
            <PrimaryButtonIcon
              height="100%"
              position={["static", "static", "absolute", "absolute"]}
              right={0}
              label={messages.search_text}
              icon="icon-search"
              onClick={handleOnSubmit.bind(this, false)}
            />
          </Div>
        )}

        {isMultiple && (
          <Div
            display="flex"
            flexDirection={"column"}
            alignItems={["stretch", "stretch", "flex-end", "flex-end"]}
            gridGap="14px"
            width={[1, 1, "540px", "540px"]}
          >
            <InputTextArea
              width={1}
              rows={4}
              cols={24}
              placeholder={messages.placeholder_search_multiple}
              value={ssnNumbers.join("\n")}
              onChange={handleOnSSNNumberChange}
              borderRadius={20}
              lineHeight={2}
              backgroundColor="transparent"
            />
            <PrimaryButtonIcon
              label={messages.search_text}
              icon="icon-search"
              onClick={handleOnSubmit.bind(this, true)}
            />
          </Div>
        )}

        <Div display="flex" flexDirection="column" gridGap={3}>
          {isMultiple && (
            <Div
              display="flex"
              backgroundColor={"var(--grey-lightest)"}
              alignItems="flex-start"
              borderRadius="10px"
              gridGap="10px"
              px="14px"
              py="9px"
              width={[1, 1, "fit-content", "fit-content"]}
            >
              <Icon name="info" rounded={true} height="24px" width="24px" />

              <Div display="flex" flexDirection="column" gridGap={2}>
                <Text>{messages.up_to_10_ids}</Text>
                <Text>{messages.seperate_id_by_comma}</Text>
              </Div>
            </Div>
          )}

          <Div
            px="14px"
            py="9px"
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            alignItems={["flex-start", "flex-start", "center", "center"]}
            backgroundColor={"var(--grey-lightest)"}
            borderRadius="10px"
            gridGap="10px"
          >
            <Div display="flex" gridGap="10px" alignItems="center">
              <Icon name="info" rounded={true} height="24px" width="24px" />

              <Text whiteSpace="nowrap">
                {messages.security_number_details}
              </Text>
            </Div>

            <LinkArrow
              pl={[3, 3, 0, 0]}
              target="_blank"
              direction="right"
              href={`${Config.WP_URL}${ROUTES.INTERNATIONELLBAKGRUNDSANALYS.URL}`}
              label={messages.search_label_click_here}
              variant="secondary"
              fontWeight="var(--medium-weight)"
              flexWrap="nowrap"
              whiteSpace="nowrap"
            >
              <Icon ml={1} name="headerarrowright" />
            </LinkArrow>
          </Div>
        </Div>
      </Div>

      {showMultipleSsnError.length > 0 && (
        <Div width={1} m={1}>
          {!isMultiple ? (
            <ColouredSemiBoldText color="var(--red)">
              {ssnNumbers.length > 0
                ? messages.error_invalid_id
                : messages.error_empty_search}
            </ColouredSemiBoldText>
          ) : (
            <ColouredSemiBoldText color="var(--red)">
              {ssnNumbers.length === 0
                ? messages.error_empty_search
                : `${
                    messages.invalid_personal_numbers
                  }:${showMultipleSsnError.join(", ")}`}
            </ColouredSemiBoldText>
          )}
        </Div>
      )}

      {isAuthenticated && !isMultiple && (
        <Div
          py="10px"
          display="flex"
          justifyContent={["center", "center", "start", "start"]}
        >
          <Link onClick={handleMultipleSsnSearch}>
            <Icon
              mr={1}
              height="18px"
              width="18px"
              name="plus"
              color={"var(--blue-dark) !important"}
            />
            <ColouredMediumWeight>
              {messages.search_several_pin}
            </ColouredMediumWeight>
          </Link>
        </Div>
      )}
    </Div>
  );
};

SSNSearch.propTypes = {
  ssnNumbers: PropTypes.array,
  onSearchSSN: PropTypes.func,
  onChange: PropTypes.func,
};

export default SSNSearch;
