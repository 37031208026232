import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import DataTable from "@components/DataTableV2/DataTable";
import TabHeader from "@components/TabHeader";
import { CASES, ACTIVITY_LOG } from "@utils/constant";
import ActivityLogDetails from "../../activityLogs/Details";

const SEARCHABLE_COLUMNS = ["id", "model", "type", "created_at", "user"];
const ActivityLogsTab = () => {
  const { messages } = useIntl();
  const { case_id } = useParams();

  const [selectedActivity, setSelectedActivity] = useState("");
  const [showActivityLog, setShowActivityLog] = useState(false);

  const handleActivityLogDetails = (values = "") => {
    setSelectedActivity(showActivityLog ? "" : values);
    setShowActivityLog(!showActivityLog);
  };

  const dataTableConfig = {
    header: {
      title: "label_subscription_packages",
    },
    enable_search: false,
    enable_filter: false,
    enable_csv_download: false,
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_query_builder: true,
    api: {
      resource: `/${CASES}/${case_id}/${ACTIVITY_LOG}`,
      method: "GET",
      search_fields: SEARCHABLE_COLUMNS,
      params: [["fields", SEARCHABLE_COLUMNS]],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
      },
      {
        title: "label_purchaser",
        db_field: "user.name",
        type: "text",
      },
      {
        db_field: "created_at",
        title: "created_at",
        type: "dateTime",
      },
      {
        title: "type_label",
        db_field: "type",
        type: "enum",
      },
      {
        title: "model",
        db_field: "model",
        type: "text",
        formatter: value => {
          const model = value?.split("App\\Models\\")?.[1];
          return model ? messages[model.toLowerCase()] : "-";
        },
      },
      {
        type: "actions",
        width: "50px",
        icon: "menu-report",
        actions: [
          {
            icon: "menu-report",
            type: "button",
            onClick: handleActivityLogDetails,
          },
        ],
      },
    ],
  };

  const tabHeader = {
    title: messages.activity_log,
    actions: [],
  };

  return (
    <>
      <TabHeader {...tabHeader} />
      <DataTable config={dataTableConfig} />
      {showActivityLog && (
        <ActivityLogDetails
          data={selectedActivity}
          handleClose={handleActivityLogDetails}
        />
      )}
    </>
  );
};

export default ActivityLogsTab;
