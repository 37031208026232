import React from "react";
import PropTypes from "prop-types";

import PrintReportDialog from "../../LegacyReport/PrintReportDialog";

const DownloadGDPRDialog = ({ onClose, onConfirm }) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <PrintReportDialog onClose={handleClose} onConfirm={handleConfirm} />
  );
};

DownloadGDPRDialog.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DownloadGDPRDialog;
