import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import customerDetailsService from "@app/services/customers/customerDetailsService";
import { deleteCustomerProfile } from "@app/services/users/deleteUserProfileService";

import Breadcrumb from "@components/Breadcrumb";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import Icon from "@components/Icon";
import Dialog from "@components/Dialog";
import { LinkArrow } from "@components/Link";
import ProgressSpinner from "@components/ProgressSpinner";
import Skeleton from "@components/Skeleton";
import Span from "@components/Span";
import { default as StyledTabs } from "@components/Tabs";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";
import AdminContainer from "@layout/AdminContainer";

import List from "@pages/admin/watchlist/list";

import { loadActiveSubscriptionData } from "@utils/common";
import {
  ROUTES,
  ADMIN_CUSTOMER_DETAILS,
  ADMIN_CUSTOMER_USERS,
  ADMIN_CUSTOMER_ORDERS,
  ADMIN_CUSTOMER_CREDITS,
  ADMIN_BILLING_ACCOUNTS,
  ROLE_ANALYSIS,
  ADMIN_WATCHLIST_URL,
  INTEGRATIONS_API,
  RAPPORT,
} from "@utils/constant";
import { formatDateAndTime } from "@utils/utils";

import Analysis from "./tabs/Analysis";
import BillingAccountsTab from "./tabs/BillingAccountsTab";
import CompanyInfo from "./tabs/CompanyInfo";
import CommentsTab from "./tabs/CommentsTab";
import IntegrationsAPILogs from "./tabs/IntegrationsAPILogs";
import Orders from "./tabs/Orders";
import Products from "./tabs/Products";
import Subscription from "../subscriptions/SubscriptionDetails";
import Users from "./tabs/Users";
import ActivityLogsTab from "./tabs/ActivityLogsTab";

const Details = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const { id = "" } = useParams();
  const { hasAllPermissions } = useAuthorization();

  const [customerData, setCustomerData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState(false);

  const [tabs, setTabs] = useState([]);

  const { location: { state = {} } = {} } = history;
  const { status } = state;
  const CustomerName =
    customerData?.company_name ||
    customerData?.firstname + " " + customerData?.lastname ||
    messages.title_customer;

  const breadCrumbItems = [
    {
      label: messages.title_customers,
      url: ROUTES.ADMIN_CUSTOMERS.URL,
    },
    {
      label: isLoading ? <Skeleton height={20} width={150} /> : CustomerName,
    },
  ];

  useEffect(() => {
    const hasUsersPermission = hasAllPermissions([
      AdminPermissions.AdminReadUsers,
    ]);

    const hasPermissionToActivityLogs = hasAllPermissions([
      AdminPermissions.AdminReadCustomersActivityLog,
    ]);

    const hasPermissionToOrders = hasAllPermissions([
      AdminPermissions.AdminReadOrders,
    ]);

    const hasPermissionToWatchlists = hasAllPermissions([
      AdminPermissions.AdminReadWatchLists,
    ]);

    const hasPermissionToProducts = hasAllPermissions([
      AdminPermissions.AdminReadProducts,
    ]);

    const hasPermissionToReports = hasAllPermissions([
      AdminPermissions.AdminReadReportAnalysis,
    ]);

    const hasPermissionToSubscriptions = hasAllPermissions([
      AdminPermissions.AdminReadSubscription,
    ]);

    const hasPermissionToBilling = hasAllPermissions([
      AdminPermissions.AdminReadBilling,
    ]);

    const customersTabContent = [
      {
        title: messages.label_details,
        content: (
          <>
            {!!Object.keys(customerData).length && (
              <CompanyInfo
                customerData={customerData}
                onCustomerDelete={handleCloseDialog}
                id={id}
                status={status}
                updateCustomerData={updateCustomerData}
              />
            )}
          </>
        ),
        url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`,
      },
    ];

    let commentsTabContent = {
      title: messages.label_comments,
      content: <CommentsTab id={id} />,
      url: `/admin/kunder/${id}/kommentarer`,
    };

    let activityLogsTabContent = {
      title: messages.activity_log,
      content: <ActivityLogsTab id={id} />,
      url: `/admin/kunder/${id}/aktivitetslogg`,
    };

    let usersTabContent = {
      title: messages.title_users,
      content: <Users customerId={id} />,
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_USERS}`,
    };

    let ordersTabContent = {
      title: messages.title_orders,
      content: <Orders customerId={id} />,
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_ORDERS}`,
    };

    let billingAccountsContent = {
      title: messages.tab_billing,
      content: (
        <BillingAccountsTab
          customer_id={id}
          updateCustomerData={updateCustomerData}
          customerData={customerData}
          activeSubscription={activeSubscription}
        />
      ),
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_BILLING_ACCOUNTS}`,
    };

    let customersApiTabContent = {
      title: messages.header_api,
      content: (
        <IntegrationsAPILogs
          customerData={customerData}
          updateCustomerDetails={updateCustomerData}
        />
      ),
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${INTEGRATIONS_API}`,
    };

    let subscriptionTabContent = {
      title: messages.title_prepaid_account,
      content: <Subscription isInCustomerDetailsPage subscriptionData={activeSubscription}/>,
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_CREDITS}`,
    };

    let analysisTabContent = {
      title: messages.menu_reports,
      content: <Analysis customerId={id} />,
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${RAPPORT}`,
    };

    let watchlistTabContent = {
      title: messages.menu_watchlist,
      content: <List customerId={id} />,
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_WATCHLIST_URL}`,
    };

    let productsTabContent = {
      title: messages.label_services,
      content: (
        <Products customerId={id} customProduct={customerData.custom_product} />
      ),
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/tjanster`,
    };

    const { analysis_type: analysisType = ROLE_ANALYSIS } = customerData;
    let tabContent = [...new Set(customersTabContent)];
    let indexValue = 0;

    if (hasUsersPermission) {
      indexValue = indexValue + 1;
      usersTabContent = { ...usersTabContent, indexValue };
      tabContent = [...tabContent, usersTabContent];
    }
    if (hasPermissionToOrders) {
      indexValue = indexValue + 1;
      ordersTabContent = { ...ordersTabContent, indexValue };
      tabContent = [...tabContent, ordersTabContent];
    }
    if (activeSubscription && hasPermissionToSubscriptions) {
      indexValue = indexValue + 1;
      subscriptionTabContent = { ...subscriptionTabContent, indexValue };
      tabContent = [...tabContent, subscriptionTabContent];
    }
    if (analysisType === ROLE_ANALYSIS && hasPermissionToReports) {
      indexValue = indexValue + 1;
      analysisTabContent = { ...analysisTabContent, indexValue };
      tabContent = [...tabContent, analysisTabContent];
    }
    if (hasPermissionToWatchlists) {
      indexValue = indexValue + 1;
      watchlistTabContent = { ...watchlistTabContent, indexValue };
      tabContent = [...tabContent, watchlistTabContent];
    }
    if (hasPermissionToProducts) {
      indexValue = indexValue + 1;
      productsTabContent = { ...productsTabContent, indexValue };
      tabContent = [...tabContent, productsTabContent];
    }
    if (hasPermissionToBilling) {
      indexValue = indexValue + 1;
      billingAccountsContent = { ...billingAccountsContent, indexValue };
      tabContent = [...tabContent, billingAccountsContent];
    }
    indexValue = indexValue + 1;
    customersApiTabContent = { ...customersApiTabContent, indexValue };
    tabContent = [...tabContent, customersApiTabContent];

    indexValue = indexValue + 1;
    commentsTabContent = { ...commentsTabContent, indexValue };
    tabContent = [...tabContent, commentsTabContent];

    if (hasPermissionToActivityLogs) {
      indexValue = indexValue + 1;
      activityLogsTabContent = { ...activityLogsTabContent, indexValue };
      tabContent = [...tabContent, activityLogsTabContent];
    }

    setTabs(tabContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllPermissions, customerData, activeSubscription]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const customerDetailsResponse = await customerDetailsService(id).then(
          ({ data }) => data.data
        );

        const subscriptionResponse = await loadActiveSubscriptionData({
          customer: customerDetailsResponse,
        });

        setCustomerData({
          ...customerDetailsResponse,
          createdAt: customerDetailsResponse.created_at
            ? formatDateAndTime(customerDetailsResponse.created_at)
            : "",
        });
        setActiveSubscription(subscriptionResponse);
      } catch (e) {
        throw new Error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, messages]);

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleDeleteDialog = async () => {
    try {
      const { id: customerId } = customerData;
      await deleteCustomerProfile({
        customerId,
      });
      history.push(ROUTES.ADMIN_CUSTOMERS.URL);
    } catch (e) {
      throw new Error(e);
    }
  };

  const updateCustomerData = data => {
    setCustomerData({
      ...customerData,
      ...data,
    });
  };

  useEffect(() => {
    const currentTab = tabs.find(tab => tab.url === window.location.pathname);
    const indexValue = currentTab?.indexValue || 0;
    if (indexValue !== activeIndex) {
      setActiveIndex(indexValue);
    }
  }, [activeIndex, tabs]);

  const onTabChange = e => {
    if (e?.index !== activeIndex) {
      setActiveIndex(e.index);
      window.history.pushState(null, "", tabs[e.index]?.url);
    }
  };

  const dialogHeader = () => (
    <Div textAlign="center" width={1}>
      <Span upper semibold fontSize="var(--fs-h4) !important">
        {messages.label_delete_customer}
      </Span>
    </Div>
  );

  const headerConfig = {
    title: CustomerName,
  };

  return (
    <div className="customer-details">
      <AdminContainer config={headerConfig} isLoading={isLoading} wide={true}>
        <Breadcrumb items={breadCrumbItems} p={0} mb={3} />

        <StyledTabs
          content={tabs}
          onTabChange={onTabChange}
          activeIndex={activeIndex}
        />

        {isLoading ? (
          <ProgressSpinner />
        ) : (
          <Div px={0} py={0}>
            {openDialog && (
              <Dialog
                header={dialogHeader}
                visible="displayBasic"
                draggable={false}
                onHide={handleCloseDialog}
                width={["90%", "90%", "90%", 445]}
              >
                <>
                  <Div
                    alignItems="center"
                    lineHeight="130%"
                    display="flex"
                    flexDirection="column"
                    mt={30}
                  >
                    <Span medium light>
                      {messages.customer_delete_message}
                    </Span>
                    <Span medium light>
                      {messages.customer_delete_description}
                    </Span>
                    <Div mt={20}>
                      <LinkArrow mr={2} info semibold>
                        {messages.label_more_about_gdpr}
                        <Icon
                          name="headerarrowright"
                          color={"var(--turquoise)"}
                        />
                      </LinkArrow>
                    </Div>
                  </Div>
                  <Div
                    mt={27}
                    display="flex"
                    justifyContent="center"
                    flexDirection={[
                      "column-reverse",
                      "column-reverse",
                      "column-reverse",
                      "row",
                    ]}
                  >
                    <PrimaryButtonOutlined
                      rounded
                      semibold
                      label={messages.label_cancel}
                      onClick={handleCloseDialog}
                      mr={[0, 0, 0, 30]}
                      width={[1, 1, 1, "45%"]}
                    />
                    <PrimaryButton
                      rounded
                      semibold
                      label={messages.label_delete}
                      onClick={handleDeleteDialog}
                      mb={[4, 4, 4, 0]}
                      width={[1, 1, 1, "45%"]}
                    />
                  </Div>
                </>
              </Dialog>
            )}
          </Div>
        )}
      </AdminContainer>
    </div>
  );
};

export default Details;
