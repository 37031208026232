export const methodProps = {
  sectionTitle: "title_method",
  description: ["desc_method_1", "desc_method_2"],
};

export const headingProps = {
  title: "title_report",
  subTitle: "title_bg_analysis",
  description: "desc_report",
};
export const consentheadingProps = {
  title: "consent_title_Request",
  subTitle: "title_bg_analysis",
  description: "desc_report",
};
export const deviationProps = {
  sectionTitle: "title_deviations",
  descriptionRight: [
    { label: "label_confidential_markings", value: "confidentiality_markings" },
    { label: "label_citizenship", value: "citizenship" },
    { label: "label_summary_imposition", value: "summary_imposition_fine" },
    { label: "label_indictment", value: "indictment" },
    { label: "label_criminal_cases", value: "criminal_cases" },
    { label: "label_judgements", value: "judgments_civil_law" },
  ],
  descriptionLeft: [
    { label: "label_ruling_in_rent", value: "ruling_rent_tribunal" },
    { label: "label_involvement_business", value: "involvment_businesses" },
    { label: "label_bank_rupties", value: "bankruptcies" },
    { label: "label_no_of_vehicles", value: "number_of_vehicles" },
    { label: "label_economy", value: "economy" },
    { label: "label_no_of_moves", value: "number_of_moves" },
  ],
  footer: "footer_deviation_props",
};

export const statementScaleProps = {
  sectionTitle: "title_statement_scale",
  description: "desc_statement_scale",
  grad: [
    {
      color: "white",
      bgColor: "green-dark",
      title: "grade",
      grades: "+3",
      statement: ["grade", "label_very_low_risk", "description_very_low_risk"],
      description: {
        title: "title_grade_very_low",
        subTitle: "subtitle_grade_very_low",
        description: "description_grade",
      },
      values: ["1 på 15 000", "(0,01 %)"],
      footer: "footer_grade_very_low",
    },
    {
      color: "white",
      bgColor: "green-dark",
      title: "grade",
      grades: "+2",
      statement: ["grade", "label_low_risk", "description_low_risk"],
      description: {
        title: "title_grade_low",
        subTitle: "subtitle_grade_low",
        description: "description_grade",
      },
      values: ["1 på 2 000", "(0,05 %)"],
      footer: "description_low_risk",
    },
    {
      color: "grey-dark",
      bgColor: "yellow-medium",
      title: "grade",
      grades: "+1",
      statement: [
        "grade",
        "label_medium_low_risk",
        "description_medium_low_risk",
      ],
      description: {
        title: "title_grade_medium_low",
        subTitle: "subtitle_grade_average",
        description: "description_grade",
      },
      values: ["1 på 400", "(0,25 %)"],
      footer: "footer_grade_medium",
    },
    {
      color: "grey-dark",
      bgColor: "yellow-medium",
      title: "grade",
      grades: "0",
      statement: ["grade", "label_medium_risk", "description_medium_risk"],
      description: {
        title: "title_grade_average",
        subTitle: "subtitle_grade_average",
        description: "description_grade",
      },
      values: ["1 på 80", "(1,25 %)"],
      footer: "footer_grade_medium",
    },
    {
      color: "grey-dark",
      bgColor: "yellow-medium",
      title: "grade",
      grades: "-1",
      statement: [
        "grade",
        "label_medium_high_risk",
        "description_medium_high_risk",
      ],
      description: {
        title: "title_grade_medium",
        subTitle: "subtitle_grade_average",
        description: "description_grade",
      },
      values: ["1 på 20", "(5 %)"],
      footer: "footer_grade_high",
    },
    {
      color: "white",
      bgColor: "red-medium",
      title: "grade",
      grades: "-2",
      statement: ["grade", "label_high_risk", "description_high_risk"],
      description: {
        title: "title_grade_high",
        subTitle: "subtitle_grade_average",
        description: "description_grade",
      },
      values: ["1 på 8", "(12,5 %)"],
      footer: "footer_grade_high",
    },
    {
      color: "white",
      bgColor: "red-medium",
      title: "grade",
      grades: "-3",
      statement: [
        "grade",
        "label_very_high_risk",
        "description_very_high_risk",
      ],
      description: {
        title: "title_grade_very_high",
        subTitle: "subtitle_grade_average",
        description: "description_grade",
      },
      values: ["1 på 3", "(33 %)"],
      footer: "footer_grade_high",
    },
  ],
  footer: "desc_footer",
};

export const recommendationProps = {
  sectionTitle: "title_recommendations",
  descriptions: ["description_indepth_1", "desc_recommendation_content"],
  contactProps: [
    {
      title: "title_contact_us",
    },
  ],
};
