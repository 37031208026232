import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import {
  BasicInformationSchema,
  InvoiceReferenceSchema,
  PaymentSchema,
} from "../UpdateBillingInfoSchema";

const BillingDetails = ({
  handleSubmit,
  handleDeleteBillingAccount,
  invoiceReference,
  shouldShowActivationToggleButton,
  shouldShowDeleteButton,
  handleToggleActiveStatus,
  payment,
  billingInfo,
  billingAccountInfo,
}) => {
  const { messages } = useIntl();
  return (
    <Div>
      <EditableFieldsCard
        title={messages.heading_basic_information}
        config={billingInfo}
        onSubmit={handleSubmit}
        validationSchema={BasicInformationSchema}
        isEditPermission={shouldShowActivationToggleButton}
      />
      <EditableFieldsCard
        title={messages.heading_invoice_reference}
        config={invoiceReference}
        onSubmit={handleSubmit}
        validationSchema={InvoiceReferenceSchema}
        isEditPermission={shouldShowActivationToggleButton}
      />
      <EditableFieldsCard
        title={messages.label_billing_info_payment}
        config={payment}
        onSubmit={handleSubmit}
        validationSchema={PaymentSchema}
        isEditPermission={shouldShowActivationToggleButton}
      />

      <Div
        mt={4}
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        gridGap={[3, 3, 4, 4]}
      >
        {shouldShowActivationToggleButton && (
          <PrimaryButton
            label={
              billingAccountInfo?.active
                ? messages.label_deactivate_billing_account
                : messages.label_activate_billing_account
            }
            onClick={handleToggleActiveStatus}
          />
        )}
        {shouldShowDeleteButton && (
          <PrimaryButtonOutlined
            width={[1, 1, "fit-content", "fit-content"]}
            label={messages.label_delete_billing_account}
            onClick={handleDeleteBillingAccount}
          />
        )}
      </Div>
    </Div>
  );
};
BillingDetails.propTypes = {
  billingInfo: PropTypes.object,
  payment: PropTypes.object,
  invoiceReference: PropTypes.object,
  shouldShowDeleteButton: PropTypes.bool,
  handleToggleActiveStatus: PropTypes.func,
  handleDeleteBillingAccount: PropTypes.func,
  shouldShowActivationToggleButton: PropTypes.bool,
  handleSubmit: PropTypes.func,
  billingAccountInfo: PropTypes.object,
};
export default BillingDetails;
